import WorklogService from '@/services/worklog-service'
import { UserWorklogs, WorklogViewType } from '@/types'
import { defineStore } from 'pinia'
import Common from '@/util/common'
import { toRaw } from 'vue'

interface WorklogState {
    userWorklogs: UserWorklogs
    jiraBaseUrl: string
    dateRangeTotalSeconds: number
}

export const useWorklogStore = defineStore('worklog', {
    state: (): WorklogState => {
        return {
            userWorklogs: {},
            jiraBaseUrl: '',
            dateRangeTotalSeconds: 0,
        }
    },

    actions: {
        async getWorklogs (startDate: string, endDate: string, worklogViewType: WorklogViewType, loggedInUserId: number, userId: number|null) {
            try {
                if (!userId) {
                    userId = loggedInUserId
                }

                const response = await WorklogService.viewWorklogs(userId, null, startDate, endDate)

                if (response) {
                    const users = toRaw(response)
                    for (const user of users) {
                        if (user.worklogs?.length) {
                            let totalSeconds = 0
                            this.userWorklogs[user.fullName] = {}

                            for (const worklog of user.worklogs) {
                                const currentGroup = worklogViewType === WorklogViewType.Date ?
                                    Common.formatDate(worklog.date) :
                                    worklog.ticket?.epicServiceItem?.epic?.name ?
                                        `${worklog.ticket.epicServiceItem.epic.name} (${worklog.ticket.epicServiceItem.epic.jiraKey})` :
                                        'No Epic'

                                if (!this.userWorklogs[user.fullName][currentGroup]) {
                                    this.userWorklogs[user.fullName][currentGroup] = {
                                        totalSeconds: worklog.timeSpentSeconds,
                                        worklogs: [worklog]
                                    }
                                } else {
                                    this.userWorklogs[user.fullName][currentGroup].totalSeconds += worklog.timeSpentSeconds
                                    this.userWorklogs[user.fullName][currentGroup].worklogs.push(worklog)
                                }

                                totalSeconds += worklog.timeSpentSeconds
                            }

                            this.dateRangeTotalSeconds = totalSeconds
                        }
                    }
                }
            } catch (err) {
                console.log(err)
            }
        },

        async getJiraBaseUrl () {
            this.jiraBaseUrl = await Common.getJiraBaseUrl()
        },
    },
})
