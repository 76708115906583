import { BaseHttpService } from '@/services/base-http-service'
import {
    ContractTemplate,
    ZohoFieldTypeSpecialFormat,
    ContractTemplateName,
    ZohoCrmDataType,
    ContractTemplateUploadType
} from '@/types'

class ContractTemplateService extends BaseHttpService {
    public async getContractTemplateNames(): Promise<ContractTemplateName[]> {
        const uri = '/external/contract-templates/names'
        const response = await this.doApiGet<ContractTemplateName[]>(uri, true)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getContractTemplates(hasFileOnly = false): Promise<ContractTemplate[]> {
        const uri = `/contract-templates?hasFileOnly=${hasFileOnly}`
        const response = await this.doApiGet<ContractTemplate[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteContractTemplate(id: number): Promise<boolean> {
        const uri = `/contract-templates/${id}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async uploadContractTemplate(formData: FormData, contractTemplateUploadType: ContractTemplateUploadType): Promise<boolean> {
        const uri = `/contract-templates/upload?contractTemplateUploadType=${contractTemplateUploadType}`
        const response = await this.doApiPost<boolean>(uri, formData, false)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    async createZohoFieldTypeSpecialFormat(zohoFieldTypeSpecialFormat: Partial<ZohoFieldTypeSpecialFormat>): Promise<ZohoFieldTypeSpecialFormat> {
        const uri = '/contract-templates/special-formats'
        const response = await this.doApiPost<ZohoFieldTypeSpecialFormat>(uri, JSON.stringify(zohoFieldTypeSpecialFormat))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    async updateZohoFieldTypeSpecialFormat(zohoFieldTypeSpecialFormatId: number, zohoFieldTypeSpecialFormat: Partial<ZohoFieldTypeSpecialFormat>): Promise<ZohoFieldTypeSpecialFormat> {
        const uri = `/contract-templates/special-formats/${zohoFieldTypeSpecialFormatId}`
        const response = await this.doApiPut<ZohoFieldTypeSpecialFormat>(uri, JSON.stringify(zohoFieldTypeSpecialFormat))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    async deleteZohoFieldTypeSpecialFormat(zohoFieldTypeSpecialFormatId: number): Promise<boolean> {
        const uri = `/contract-templates/special-formats/${zohoFieldTypeSpecialFormatId}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createDownloadContract(type: ZohoCrmDataType, recordId: string, templateName: string, contractTemplateUploadType: ContractTemplateUploadType) {
        const uri = `/external/contracts/create-download-contract`
        const response = await this.download(uri, 'application/rtf', JSON.stringify({
            type,
            recordId,
            templateName,
            contractTemplateUploadType,
        }), true)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async downloadContractTemplate(fileName: string): Promise<boolean> {
        const uri = `/contract-templates/download?fileName=${fileName}`
        const response = await this.download(uri, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new ContractTemplateService()
