import { BaseHttpService } from '@/services/base-http-service'
import { Sprint } from '@/types'

class SprintService extends BaseHttpService {
    public async getSprint(id: number): Promise<Sprint> {
        const uri = `/sprints/${id}`
        const response = await this.doApiGet<Sprint>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getSprints(activeAndFutureOnly = false): Promise<Sprint[]> {
        const uri = `/sprints?activeAndFutureOnly=${activeAndFutureOnly}`
        const response = await this.doApiGet<Sprint[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getSprintReport(id: number, boardJiraId: number, sprintJiraId: number): Promise<any> {
        const uri = `/sprints/${id}/report?boardJiraId=${boardJiraId}&sprintJiraId=${sprintJiraId}`
        const response = await this.doApiGet<any>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async loadSprints(): Promise<boolean> {
        const uri = '/sprints/load'
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new SprintService()
