import { BaseHttpService } from '@/services/base-http-service'
import { ReportProjectWorklog, User, Worklog } from '@/types'

class WorklogService extends  BaseHttpService {
    public async getWorklog(id: number): Promise<Worklog> {
        const uri = `/worklogs/${id}`
        const response = await this.doApiGet<Worklog>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getWorklogs(userId: number, ticketId: number|null = null, startDate = '', endDate = ''): Promise<Worklog[]> {
        let queryString = `?userId=${userId}`

        if (ticketId) {
            queryString += `&ticketId=${ticketId}`
        }

        if (startDate && endDate) {
            queryString += `&startDate=${startDate}&endDate=${endDate}`
        }

        const uri = `/worklogs${queryString}`
        const response = await this.doApiGet<Worklog[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    // todo in future allow for all users for admin or maybe multi-select users
    public async viewWorklogs(userId: number, ticketId: number|null = null, startDate = '', endDate = ''): Promise<User[]> {
        let queryString = `?userId=${userId}`

        if (ticketId) {
            queryString += `&ticketId=${ticketId}`
        }

        if (startDate && endDate) {
            queryString += `&startDate=${startDate}&endDate=${endDate}`
        }

        const uri = `/users/${userId}/worklogs/${queryString}`
        const response = await this.doApiGet<User[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async loadWorklogs(startDate: string, endDate: string): Promise<boolean> {
        const uri = '/worklogs/load'
        const response = await this.doApiPost<boolean>(uri, JSON.stringify({ startDate: startDate, endDate: endDate }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getWorklogsForReport(startDate: string, endDate: string, projectKeys: string[] = [], username = ''): Promise<ReportProjectWorklog[]> {
        const uri = '/worklogs/report-worklogs'
        const response = await this.doApiPost<ReportProjectWorklog[]>(uri, JSON.stringify({
            startDate: startDate,
            endDate: endDate,
            projectKeys: projectKeys,
            username: username
        }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteWorklogByWorklogId(worklogId: number): Promise<boolean> {
        const uri = `/worklogs/${worklogId}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new WorklogService()
