import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import TransparencyReportView from '@/views/TransparencyReportView.vue'
import EpicServiceItemView from '@/views/EpicServiceItemView.vue'
import EpicView from '@/views/EpicView.vue'
import ServiceItemView from '@/views/ServiceItemView.vue'
import StatusView from '@/views/StatusView.vue'
import UserView from '@/views/UserView.vue'
import ProjectView from '@/views/ProjectView.vue'
import TicketView from '@/views/TicketView.vue'
import WorklogView from '@/views/WorklogView.vue'
import PlanningView from '@/views/PlanningView.vue'
import SkillLevelView from '@/views/SkillLevelView.vue'
import SkillView from '@/views/SkillView.vue'
import IssueTypeView from '@/views/IssueTypeView.vue'
import JiraGroupView from '@/views/JiraGroupView.vue'
import AlertLogView from '@/views/AlertLogView.vue'
import TimesheetView from '@/views/TimesheetView.vue'
import StickyNoteView from '@/views/StickyNoteView.vue'
import StoryPointInformationView from '@/views/StoryPointInformationView.vue'
import SprintInformationView from '@/views/SprintInformationView.vue'
import ProjectStatusView from '@/views/ProjectStatusView.vue'
import TimeActivityView from '@/views/TimeActivityView.vue'
import JeopardyGameView from '@/views/JeopardyGameView.vue'
import JeopardyTeamView from '@/views/JeopardyTeamView.vue'
import DownloadsView from '@/views/ContractCreateAndDownloadView.vue'
import QuickBooksAuthorizeView from '@/views/QuickBooksAuthorizeView.vue'
import ClientView from '@/views/ClientView.vue'
import { authGuard } from '@/auth/auth-guard'
import { useGlobalStore } from '@/stores/global'
import { storeToRefs } from 'pinia'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path:'/home',
    name: 'home',
    component: HomeView,
    beforeEnter: authGuard,
  },
  {
    path: '/timesheets',
    name: 'timesheets',
    component: TimesheetView,
    beforeEnter: authGuard,
  },
  {
    path: '/story-point-information',
    name: 'story point information',
    component: StoryPointInformationView,
    beforeEnter: authGuard,
  },
  {
    path: '/project-status',
    name: 'project status',
    component: ProjectStatusView,
    beforeEnter: authGuard,
  },
  {
    path: '/sprint-information',
    name: 'sprint information',
    component: SprintInformationView,
    beforeEnter: authGuard,
  },
  {
    path: '/sticky-notes',
    name: 'sticky notes',
    component: StickyNoteView,
    beforeEnter: authGuard,
  },
  {
    path: '/transparency-report',
    name: 'transparency report',
    component: TransparencyReportView,
    beforeEnter: authGuard,
  },
  {
    path: '/epic-service-items',
    name: 'epic service items',
    component: EpicServiceItemView,
    beforeEnter: authGuard,
  },
  {
    path: '/epics',
    name: 'epics',
    component: EpicView,
    beforeEnter: authGuard,
  },
  {
    path: '/alert-logs',
    name: 'alert logs',
    component: AlertLogView,
    beforeEnter: authGuard,
  },
  {
    path: '/jira-groups',
    name: 'jira groups',
    component: JiraGroupView,
    beforeEnter: authGuard,
  },
  {
    path: '/issue-types',
    name: 'issue types',
    component: IssueTypeView,
    beforeEnter: authGuard,
  },
  {
    path: '/service-items',
    name: 'service items',
    component: ServiceItemView,
    beforeEnter: authGuard,
  },
  {
    path: '/statuses',
    name: 'statuses',
    component: StatusView,
    beforeEnter: authGuard,
  },
  {
    path: '/time-activity',
    name: 'time activity',
    component: TimeActivityView,
    beforeEnter: authGuard,
  },
  {
    path: '/users',
    name: 'users',
    component: UserView,
    beforeEnter: authGuard,
  },
  {
    path: '/projects',
    name: 'projects',
    component: ProjectView,
    beforeEnter: authGuard,
  },
  {
    path: '/clients',
    name: 'clients',
    component: ClientView,
    beforeEnter: authGuard,
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: TicketView,
    beforeEnter: authGuard,
  },
  {
    path: '/worklogs',
    name: 'worklogs',
    component: WorklogView,
    beforeEnter: authGuard,
  },
  {
    path: '/planning',
    name: 'planning',
    component: PlanningView,
    beforeEnter: authGuard,
  },
  {
    path: '/skill-levels',
    name: 'skill levels',
    component: SkillLevelView,
    beforeEnter: authGuard,
  },
  {
    path: '/skills',
    name: 'skills',
    component: SkillView,
    beforeEnter: authGuard,
  },
  {
    path: '/jeopardy-game',
    name: 'jeopardy game',
    component: JeopardyGameView,
    beforeEnter: authGuard,
  },
  {
    path: '/jeopardy-team',
    name: 'jeopardy team',
    component: JeopardyTeamView,
    beforeEnter: authGuard,
  },
  {
    path: '/contract-create-download',
    name: 'contract create and download',
    component: DownloadsView,
  },
  {
    path: '/quickbooks-authorize',
    name: 'quickbooks authorize',
    component: QuickBooksAuthorizeView,
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeResolve(async (to, from, next) => {
  if (to.path !== '/contract-create-download' && to.path !== '/quickbooks-authorize') {
    const { loggedInUser } = storeToRefs(useGlobalStore())
    const { setIsAdmin, setIsSuperAdmin, getLoggedInUser } = useGlobalStore()

    if (!loggedInUser.value) {
      await getLoggedInUser(true)
      setIsAdmin(loggedInUser.value!.role === 'admin')
      setIsSuperAdmin(loggedInUser.value!.role === 'super-admin')
    }
  }

  next()
})

export default router
