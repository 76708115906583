import { BaseHttpService } from '@/services/base-http-service'

class BoardService extends BaseHttpService {
    public async loadBoards(): Promise<boolean> {
        const uri = '/boards/load'
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new BoardService()
