import ProjectService from '@/services/project-service'
import { Project, RecordStatus } from '@/types'
import { defineStore } from 'pinia'
import Common from '@/util/common'

interface EpicServiceItemState {
    projects: Project[]
    letters: string[]
    anchors: string[]
}

export const useEpicServiceItemStore = defineStore('epicServiceItem', {
    state: (): EpicServiceItemState => {
        return {
            projects: [],
            letters: [],
            anchors: [],
        }
    },

    actions: {
        async getProjects (projectRecordStatus: RecordStatus, epicServiceItemRecordStatus: RecordStatus, userId: number|null = null) {
            try {
                this.projects = await ProjectService.getProjects(projectRecordStatus, [], true, epicServiceItemRecordStatus, userId)
            } catch (err) {
                console.log(err)
            }
        },

        getLettersAndAnchors() {
            const response = Common.getLettersAndAnchors(this.projects, 'name')

            this.letters = response.letters
            this.anchors = response.anchors
        },
    },
})
