import { BaseHttpService } from '@/services/base-http-service'
import { ActivityTimeline, Project, User, UserPlanningActivityTimeline } from '@/types'

class ActivityTimelineService extends BaseHttpService {
    public async getActivityTimeline(id: number): Promise<ActivityTimeline> {
        const uri = `/activity-timelines/${id}`
        const response = await this.doApiGet<ActivityTimeline>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateActivityTimeline(id: number, activityTimeline: Partial<ActivityTimeline>): Promise<ActivityTimeline> {
        const uri = `/activity-timelines/${id}`
        const response = await this.doApiPut<ActivityTimeline>(uri, JSON.stringify({ data: activityTimeline }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getEpicActivityTimelines(startDate: string, endDate: string, includeZeytechGeneralProject: boolean, projectId: number|null = null): Promise<Project[]> {
        let queryString = `?activityTimelineType=epic&startDate=${startDate}&endDate=${endDate}&includeZeytechGeneralProject=${includeZeytechGeneralProject}`

        if (projectId) {
            queryString += `&projectId=${projectId}`
        }

        const uri = `/activity-timelines${queryString}`
        const response = await this.doApiGet<Project[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getPotentialAndNewProjectActivityTimelines(startDate: string, endDate: string, includeTeam: boolean): Promise<UserPlanningActivityTimeline[]> {
        const queryString = `?activityTimelineType=newProject&startDate=${startDate}&endDate=${endDate}&includeTeam=${includeTeam}`

        const uri = `/activity-timelines${queryString}`
        const response = await this.doApiGet<UserPlanningActivityTimeline[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getProjectActivityTimelines(startDate: string, endDate: string, includeZeytechGeneralProject: boolean, projectId: number|null = null): Promise<Project[]> {
        let queryString = `?activityTimelineType=project&startDate=${startDate}&endDate=${endDate}&includeZeytechGeneralProject=${includeZeytechGeneralProject}`

        if (projectId) {
            queryString += `&projectId=${projectId}`
        }

        const uri = `/activity-timelines${queryString}`
        const response = await this.doApiGet<Project[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getUserActivityTimelines(startDate: string, endDate: string, includeZeytechAutomationsUser: boolean, userId: number|null = null): Promise<User[]> {
        let queryString = `?activityTimelineType=user&startDate=${startDate}&endDate=${endDate}&includeZeytechAutomationsUser=${includeZeytechAutomationsUser}`

        if (userId) {
            queryString += `&userId=${userId}`
        }

        const uri = `/activity-timelines${queryString}`
        const response = await this.doApiGet<User[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async loadActivityTimelines(startDate: string, endDate: string, updateTickets = true, userId: number|null = null): Promise<boolean> {
        const uri = '/activity-timelines/load'
        const response = await this.doApiPost<boolean>(uri, JSON.stringify({ startDate: startDate, endDate: endDate, updateTickets: updateTickets, userId: userId }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new ActivityTimelineService()
