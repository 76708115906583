import { BaseHttpService } from '@/services/base-http-service'
import { TimeOffRequest, UserTimesheets } from '@/types'

class TSheetsService extends BaseHttpService {
    public async importWorklogs(startDate: string, endDate: string, userId: number): Promise<boolean> {
        const uri = `/tsheets/import-worklogs?startDate=${startDate}&endDate=${endDate}&userId=${userId}`
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async viewTimesheets(startDate: string, endDate: string, userIds: number[]): Promise<UserTimesheets> {
        const uri = `/tsheets/view-timesheet?startDate=${startDate}&endDate=${endDate}&userIds=${JSON.stringify(userIds)}`
        const response = await this.doApiGet<UserTimesheets>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getTimeOff(startDate: string, endDate: string, tsheetsUserIds: string[], jobcodeType = ''): Promise<any> {
        const uri = `/tsheets/time-off?startDate=${startDate}&endDate=${endDate}&tsheetsUserIds=${JSON.stringify(tsheetsUserIds)}&jobcodeType=${jobcodeType}`
        const response = await this.doApiGet<any>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteTimesheet(id: string): Promise<boolean> {
        const uri = `/tsheets/delete-timesheet/${id}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateApprovedToSubmittedTo(tsheetsUserId: string, submitApproveType: 'approve'|'submit', toDate: string) {
        const data = {
            tsheetsUserId: tsheetsUserId,
            submitApproveType: submitApproveType,
            toDate: toDate,
        }

        const uri = '/tsheets/approved-submitted-update'
        const response = await this.doApiPut(uri, JSON.stringify(data))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateServiceItem(timesheetId: string, serviceItem: string, billable: 'Yes'|'No'): Promise<boolean> {
        const uri = '/tsheets/timesheet-update-service-item'
        const response = await this.doApiPut<boolean>(uri, JSON.stringify({ timesheetId, serviceItem, billable }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async requestTimeOff(timeOffRequest: TimeOffRequest): Promise<boolean> {
        const uri = '/tsheets/time-off-request'
        const response = await this.doApiPost<boolean>(uri, JSON.stringify({ timeOffRequest }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async approveTimeOffRequestEntry(id: string): Promise<boolean> {
        const uri = `/tsheets/time-off-request-entry/${id}`
        const response = await this.doApiPut<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteTimeOffRequestEntry(id: string): Promise<boolean> {
        const uri = `/tsheets/time-off-request-entry/${id}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getCustomFieldItems(customFieldId: number, name = ''): Promise<any> {
        const uri = `/tsheets/custom-field-items?customFieldId=${customFieldId}${name ? `&name=${name}` : ''}`
        const response = await this.doApiGet<any>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getCustomFieldItemFilters(userId = '', jobCodeId = '', modifiedSince = ''): Promise<any> {
        const uri = `/tsheets/custom-item-filters?userId=${userId}&jobCodeId=${jobCodeId}&modifiedSince=${modifiedSince}`
        const response = await this.doApiGet<any>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async addCustomFieldItemFilter(filterType: 'jobcodes'|'users', filterTypeId: number, serviceItemId: number): Promise<boolean> {
        const data = {
            filterType: filterType,
            filterTypeId: filterTypeId,
            serviceItemId: serviceItemId,
        }

        const uri = '/tsheets/custom-item-filters'
        const response = await this.doApiPost<boolean>(uri, JSON.stringify({ data }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async changeCustomFieldItemFiltersStatus(filterId: number, active: boolean): Promise<boolean> {
        const data = {
            active: active,
        }

        const uri = `/tsheets/custom-item-filters/${filterId}`
        const response = await this.doApiPut<boolean>(uri, JSON.stringify({ data }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async addUserJobCodeAssignment(userId: string, jobCodeId: string): Promise<any> {
        const uri = `/tsheets/job-code-assignments?userId=${userId}&jobCodeId=${jobCodeId}`
        const response = await this.doApiPost<any>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteUserJobCodeAssignment(assignmentId: number): Promise<boolean> {
        const uri = `/tsheets/job-code-assignments/${assignmentId}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new TSheetsService()
