import { BaseHttpService } from '@/services/base-http-service'
import { JiraGroup } from '@/types'

class JiraGroupService extends BaseHttpService {
    public async getJiraGroup(id: number): Promise<JiraGroup> {
        const uri = `/jira-groups/${id}`
        const response = await this.doApiGet<JiraGroup>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getJiraGroups(): Promise<JiraGroup[]> {
        const uri = '/jira-groups'
        const response = await this.doApiGet<JiraGroup[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async loadJiraGroups(): Promise<boolean> {
        const uri = '/jira-groups/load'
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new JiraGroupService()
