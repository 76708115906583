import { BaseHttpService } from '@/services/base-http-service'
import {
    EditingServiceItemUser,
    NewTSheetsServiceItemUser,
    RecordStatus,
    ServiceItem,
    TSheetsServiceItemFilterData,
    TSheetsServiceItemInformation
} from '@/types'

class ServiceItemService extends BaseHttpService {
    public async getServiceItem(id: number): Promise<ServiceItem> {
        const uri = `/service-items/${id}`
        const response = await this.doApiGet<ServiceItem>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getServiceItemByName(name: string): Promise<ServiceItem> {
        const uri = `/service-items?serviceItemName=${encodeURIComponent(name)}`
        const response = await this.doApiGet<ServiceItem>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getServiceItems(recordStatus: RecordStatus, epicName = '', onlyWithoutEpic = false, userId: number|null = null): Promise<ServiceItem[]> {
        const uri = `/service-items?recordStatus=${recordStatus}&epicName=${epicName}&onlyWithoutEpic=${onlyWithoutEpic}${userId ? `&userId=${userId}` : ''}`
        const response = await this.doApiGet<ServiceItem[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createServiceItem(partialServiceItem: Partial<ServiceItem>, serviceItemUsers: NewTSheetsServiceItemUser[],
                                   projectId: number, epicId: number): Promise<ServiceItem> {
        const uri = '/service-items'
        const response = await this.doApiPost<ServiceItem>(uri,
            JSON.stringify( { partialServiceItem, serviceItemUsers, projectId, epicId }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateServiceItem(partialServiceItem: Partial<ServiceItem>): Promise<ServiceItem> {
        const uri = `/service-items/${partialServiceItem.id}`
        const response = await this.doApiPut<ServiceItem>(uri, JSON.stringify({ partialServiceItem }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async inactivateServiceItem(id: number): Promise<boolean> {
        const uri = `/service-items/${id}/inactivate`
        const response = await this.doApiPut<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateViaQuickBooksServicesTable(): Promise<boolean> {
        const uri = '/service-items/update-via-quick-books-table'
        const response = await this.doApiPut<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getOrSetTSheetsInformation(id: number, tsheetsServiceItemFilterData: TSheetsServiceItemFilterData): Promise<TSheetsServiceItemInformation> {
        const uri = `/service-items/${id}/tsheets-information`
        const response = await this.doApiPost<TSheetsServiceItemInformation>(uri, JSON.stringify({ tsheetsServiceItemFilterData }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async loadServiceItems(): Promise<boolean> {
        const uri = '/service-items/load'
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async addMultipleUsers(projectId: number, epicId: number, serviceItemId: number, editingServiceItemUsers: EditingServiceItemUser[]): Promise<boolean> {
        const uri = `/service-items/${serviceItemId}/add-multiple-users`
        const response = await this.doApiPost<boolean>(uri, JSON.stringify({ projectId, epicId, editingServiceItemUsers }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new ServiceItemService()
