import ClientService from '@/services/client-service'
import { ZohoClient, RecordStatus } from '@/types'
import { defineStore } from 'pinia'
import Common from '@/util/common'
import { useToast } from 'vue-toastification'

const toast = useToast()

interface ClientState {
    clients: ZohoClient[]
    editingClient: Partial<ZohoClient>
    originalClient: Partial<ZohoClient>
    letters: string[]
    anchors: string[]
    isSaving: boolean
}

export const useClientStore = defineStore('client', {
    state: (): ClientState => {
        return {
            clients: [],
            editingClient: {
                id: -1,
                name: '',
                zohoId: '',
                contactEmail: '',
                street1: '',
                street2: '',
                city: '',
                state: '',
                postalCode: '',
                country: '',
                active: true,
            },
            originalClient: {
                id: -1,
                name: '',
                zohoId: '',
                contactEmail: '',
                street1: '',
                street2: '',
                city: '',
                state: '',
                postalCode: '',
                country: '',
                active: true,
            },
            letters: [],
            anchors: [],
            isSaving: false,
        }
    },

    actions: {
        resetClient() {
            this.editingClient = {
                name: '',
                zohoId: '',
                contactEmail: '',
                street1: '',
                street2: '',
                city: '',
                state: '',
                postalCode: '',
                country: '',
                active: true,
            }
            this.originalClient = JSON.parse(JSON.stringify(this.editingClient))
        },

        async getClient(id: number) {
            try {
                this.editingClient = await ClientService.getClient(id)
                this.originalClient = JSON.parse(JSON.stringify(this.editingClient))
            } catch (err) {
                console.log(err)
                toast.error(`Error: ${err}`)
            }
        },

        async getClients(recordStatus = RecordStatus.Active) {
            try {
                this.clients = await ClientService.getClients(recordStatus)
            } catch (err) {
                console.log(err)
                toast.error(`Error: ${err}`)
            }
        },

        async saveClient() {
            try {
                this.isSaving = true

                if (!this.editingClient.id) {
                    this.editingClient = await ClientService.createClient(this.editingClient)
                } else {
                    this.editingClient = await ClientService.updateClient(this.editingClient.id, this.editingClient)
                }

                this.originalClient = JSON.parse(JSON.stringify(this.editingClient))

                await this.getClients()

                toast.success('Client saved')
            } catch (err) {
                console.log(err)
                toast.error(`Error: ${err}`)
            } finally {
                this.isSaving = false
            }
        },

        getLettersAndAnchors() {
            const response = Common.getLettersAndAnchors(this.clients, 'name')

            this.letters = response.letters
            this.anchors = response.anchors
        },
    },
})
