import { defineStore } from 'pinia'
import JiraGroupService from '@/services/jira-group-service'
import { JiraGroup } from '@/types'

interface JiraGroupState {
    jiraGroups: JiraGroup[]
}

export const useJiraGroupStore = defineStore('jiraGroup', {
    state: (): JiraGroupState => {
        return {
            jiraGroups: [],
        }
    },

    actions: {
        async getJiraGroups () {
            try {
                this.jiraGroups = await JiraGroupService.getJiraGroups()
            } catch (err) {
                console.log(err)
            }
        },
    },
})
