import { defineStore } from 'pinia'
import SkillLevelService from '@/services/skill-level-service'
import { SkillLevel } from '@/types'

interface SkillLevelState {
    skillLevels: SkillLevel[]
}

export const useSkillLevelStore = defineStore('skill-levels', {
    state: (): SkillLevelState => {
        return {
            skillLevels: [],
        }
    },

    actions: {
        async getSkillLevels () {
            try {
                this.skillLevels = await SkillLevelService.getSkillLevels()
            } catch (err) {
                console.log(err)
            }
        }
    },
})
