import { BaseHttpService } from '@/services/base-http-service'
import { SecureNote } from '@/types'

class SecureNoteService extends BaseHttpService {
    public async getSecureNote(token: string): Promise<SecureNote> {
        const uri = `/secure-notes/${token}`
        const response = await this.doApiGet<SecureNote>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getSecureNotes(userId: number | null = null): Promise<SecureNote[]> {
        const uri = `/secure-notes${userId ? `?userId=${userId}` : ''}`
        const response = await this.doApiGet<SecureNote[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createSecureNote(secureNote: SecureNote): Promise<SecureNote> {
        const uri = '/secure-notes'
        const response = await this.doApiPost<SecureNote>(uri, JSON.stringify({ secureNote }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateSecureNote(secureNote: SecureNote, sendSecureLink = false): Promise<SecureNote> {
        const uri = `/secure-notes/${secureNote.token}`
        const response = await this.doApiPut<SecureNote>(uri, JSON.stringify({ secureNote, sendSecureLink }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteSecureNote(token: string): Promise<boolean> {
        const uri = `/secure-notes/${token}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async resendSecureLink(token: string): Promise<boolean> {
        const uri = `/secure-notes/${token}/resend-link`
        const response = await this.doApiPut<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new SecureNoteService()
