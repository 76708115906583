import { BaseHttpService } from '@/services/base-http-service'

class TimeOffTypeUserService extends BaseHttpService {
    public async loadTimeOffTypeUsers(): Promise<boolean> {
        const uri = '/time-off-type-users/load'
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new TimeOffTypeUserService()
