import { BaseHttpService } from '@/services/base-http-service'
import { IssueType } from '@/types'

class IssueTypeService extends BaseHttpService {
    public async getIssueType(id: number): Promise<IssueType> {
        const uri = `/issue-types/${id}`
        const response = await this.doApiGet<IssueType>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getIssueTypes(): Promise<IssueType[]> {
        const uri = '/issue-types'
        const response = await this.doApiGet<IssueType[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async loadIssueTypes(): Promise<boolean> {
        const uri = '/issue-types/load'
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new IssueTypeService()
