import { defineStore } from 'pinia'
import IssueTypeService from '@/services/issue-type-service'
import { IssueType } from '@/types'

interface IssueTypeState {
    issueTypes: IssueType[]
}

export const useIssueTypeStore = defineStore('issueType', {
    state: (): IssueTypeState => {
        return {
            issueTypes: [],
        }
    },

    actions: {
        async getIssueTypes() {
            try {
                this.issueTypes = await IssueTypeService.getIssueTypes()
            } catch (err) {
                console.log(err)
            }
        }
    },
})
