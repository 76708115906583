import { defineStore } from 'pinia'
import SkillService from '@/services/skill-service'
import { CategorySkill } from '@/types'
import { toRaw } from 'vue'
import Common from '@/util/common'

interface SkillState {
    categorySkills: CategorySkill[]
    letters: string[]
    anchors: string[]
}

export const useSkillStore = defineStore('skill', {
    state: (): SkillState => {
        return {
            categorySkills: [],
            letters: [],
            anchors: [],
        }
    },

    actions: {
        async getSkills () {
            try {
                const skillKeys: any = {}
                const skills = await SkillService.getSkills()

                if (skills && toRaw(skills).length) {
                    let index = -1

                    for (const skill of toRaw(skills)) {
                        if (!skillKeys[skill.category]) {
                            skillKeys[skill.category] = skill.category
                            index++
                            this.categorySkills.push({
                                category: skill.category,
                                skills: [skill]
                            })
                        } else {
                            this.categorySkills[index].skills.push(skill)
                        }
                    }
                }
            } catch (err) {
                console.log(err)
            }
        },

        getLettersAndAnchors() {
            const response = Common.getLettersAndAnchors(this.categorySkills, 'category')

            this.letters = response.letters
            this.anchors = response.anchors
        },
    },
})
