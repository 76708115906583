import { BaseHttpService } from '@/services/base-http-service'
import { JeopardyCategory, JeopardyGame, JeopardyQuestion, JeopardyQuestionTeam, JeopardyTeam } from '@/types'

class JeopardyGameService extends BaseHttpService {
    public async clearJeopardyGame(id: number): Promise<boolean> {
        const uri = `/jeopardy-game/${id}/clear-game`
        const response = await this.doApiPut<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getGame(id: number): Promise<JeopardyGame> {
        const uri = `/jeopardy-game/${id}`
        const response = await this.doApiGet<JeopardyGame>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getGames(includePreloads: boolean): Promise<JeopardyGame[]> {
        const uri = `/jeopardy-game?includePreloads=${includePreloads}`
        const response = await this.doApiGet<JeopardyGame[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateGame(id: number, game: Partial<JeopardyGame>): Promise<JeopardyGame> {
        const uri = `/jeopardy-game/${id}`
        const response = await this.doApiPut<JeopardyGame>(uri, JSON.stringify({ game }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createTeam(team: Partial<JeopardyTeam>): Promise<JeopardyTeam> {
        const uri = '/jeopardy-team'
        const response = await this.doApiPost<JeopardyTeam>(uri, JSON.stringify({ team }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateCategory(id: number, category: Partial<JeopardyCategory>): Promise<JeopardyCategory> {
        const uri = `/jeopardy-category/${id}`
        const response = await this.doApiPut<JeopardyCategory>(uri, JSON.stringify({ category }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateQuestion(id: number, question: Partial<JeopardyQuestion>): Promise<JeopardyQuestion> {
        const uri = `/jeopardy-question/${id}`
        const response = await this.doApiPut<JeopardyQuestion>(uri, JSON.stringify({ question }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateTeam(id: number, team: Partial<JeopardyTeam>): Promise<JeopardyTeam> {
        const uri = `/jeopardy-team/${id}`
        const response = await this.doApiPut<JeopardyTeam>(uri, JSON.stringify({ team }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createJeopardyQuestionTeam(questionTeam: Partial<JeopardyQuestionTeam>): Promise<JeopardyQuestionTeam> {
        const uri = '/jeopardy-question-team'
        const response = await this.doApiPost<JeopardyQuestionTeam>(uri, JSON.stringify({ questionTeam }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async buzzIn (jeopardyGameId: number, jeopardyTeamId: number): Promise<boolean> {
        const uri = `/jeopardy-game/${jeopardyGameId}/buzz-in`
        const response = await this.doApiPost<boolean>(uri, JSON.stringify({ jeopardyTeamId }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new JeopardyGameService()
