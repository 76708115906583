import DealService from '@/services/deal-service'
import DealCommissionService from '@/services/Zoho/deal-commission-service'
import { ZohoCommissionAssignments, ZohoDeal, ZohoDealAndCommissionFilter, ZohoDealCommission } from '@/types'
import { defineStore, storeToRefs } from 'pinia'
import { useToast } from 'vue-toastification'
import { useGlobalStore } from '@/stores/global'

const toast = useToast()

interface DealState {
    deals: ZohoDeal[]
    dealCommissions: ZohoDealCommission[]
    editingDeal: ZohoDeal|null
    originalDeal: ZohoDeal|null
    isSaving: boolean
    filter: ZohoDealAndCommissionFilter
}

export const useDealStore = defineStore('deal', {
    state: (): DealState => {
        return {
            deals: [],
            dealCommissions: [],
            editingDeal: null,
            originalDeal: null,
            isSaving: false,
            filter: {
                zohoClientId: null,
                commissionAssignmentUserId: null,
                commissionEffectiveDate: null,
                notAppliedOnly: true,
            },
        }
    },

    actions: {
        resetDeal() {
            this.editingDeal = null
            this.originalDeal = null
        },

        resetFilter() {
            const { loggedInUser, isSuperAdmin } = storeToRefs(useGlobalStore())

            this.filter = {
                zohoClientId: null,
                commissionAssignmentUserId: !isSuperAdmin.value ? loggedInUser.value!.id : null,
                commissionEffectiveDate: null,
                notAppliedOnly: true,
            }
        },

        async getDeal(id: number) {
            try {
                this.editingDeal = await DealService.getDeal(id)
                this.originalDeal = JSON.parse(JSON.stringify(this.editingDeal))
            } catch (err) {
                console.log(err)
                toast.error(`Error: ${err}`)
            }
        },

        async getDeals() {
            try {
                this.deals = await DealService.getDeals(this.filter)
            } catch (err) {
                console.log(err)
                toast.error(`Error: ${err}`)
            }
        },

        async saveDeal(zohoCommissionAssignments: ZohoCommissionAssignments) {
            try {
                this.isSaving = true

                this.editingDeal = await DealService.updateDeal(this.editingDeal!.id!, this.editingDeal!, zohoCommissionAssignments)
                this.originalDeal = JSON.parse(JSON.stringify(this.editingDeal))

                toast.success('Deal saved')
            } catch (err) {
                console.log(err)
                toast.error(`Error: ${err}`)
            } finally {
                this.isSaving = false
            }
        },

        async getDealCommissions() {
            try {
                this.dealCommissions = await DealCommissionService.getDealCommissions(this.filter)
            } catch (err) {
                console.log(err)
                toast.error(`Error: ${err}`)
            }
        },
    },
})
