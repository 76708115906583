import { defineStore } from 'pinia'
import AlertLogService from '@/services/alert-log-service'
import { AlertLog } from '@/types'

interface AlertLogState {
    alertLogs: AlertLog[]
}

export const useAlertLogStore = defineStore('alertLog', {
    state: (): AlertLogState => {
        return {
            alertLogs: [],
        }
    },

    actions: {
        async getAlertLogs (startDate: string, endDate: string) {
            try {
                this.alertLogs = await AlertLogService.getAlertLogs(startDate, endDate)
            } catch (err) {
                console.log(err)
            }
        },
    },
})
