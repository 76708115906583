import { defineStore } from 'pinia'
import TSheetsService from '@/services/TSheets/tsheets-service'
import Common from '@/util/common'
import { TSheetsApproveSubmitType, UserTimesheetsByPayPeriod } from '@/types'
import { toRaw } from 'vue'
import UserService from '@/services/user-service'

interface TimesheetState {
    loading: boolean
    userTimesheets: UserTimesheetsByPayPeriod
    canApprove: boolean
    canUnapprove: boolean
    canSubmit: boolean
    canUnsubmit: boolean
}

export const useTimesheetStore = defineStore('timesheet', {
    state: (): TimesheetState => {
        return {
            loading: false,
            userTimesheets: {},
            canApprove: false,
            canUnapprove: false,
            canSubmit: false,
            canUnsubmit: false,
        }
    },

    actions: {
        // api currently works with multiple userIds, but functionality is not yet present in ui, so only pulling selected user
        // todo add userIds params once allowing to view multiple users, but also have to do the submit/unsubmit per user
        async viewTimesheets (startDate: string, endDate: string, userId: number) {
            this.setLoading(true)

            try {
                const response = await TSheetsService.viewTimesheets(startDate, endDate, [userId])

                if (response) {
                    const userInfo = toRaw(response)
                    for (const key of Object.keys(userInfo)) {
                        this.userTimesheets[key] = {
                            user: userInfo[key].user,
                            userTimesheet: {},
                        }
                        
                        if (userInfo[key].timesheets.length) {
                            let currentDate = ''
                            let currentDateTotalSeconds = 0

                            let hasSubmitted = false
                            let hasUnsubmitted = false
                            let hasApproved = false
                            let hasUnapproved = false

                            for (const timesheet of userInfo[key].timesheets) {
                                if (timesheet.date !== currentDate) {
                                    currentDate = timesheet.date
                                    if (currentDate >= Common.dateOnly(this.userTimesheets[key].user.approvedTo)) {
                                        hasUnapproved = true

                                        if (currentDate >= Common.dateOnly(this.userTimesheets[key].user.submittedTo)) {
                                            hasUnsubmitted = true
                                        } else {
                                            hasSubmitted = true
                                        }
                                    } else {
                                        hasApproved = true
                                    }
                                    currentDateTotalSeconds = timesheet.duration
                                    this.userTimesheets[key].userTimesheet[currentDate] = {
                                        totalSeconds: currentDateTotalSeconds,
                                        timesheets: [timesheet]
                                    }
                                } else {
                                    this.userTimesheets[key].userTimesheet[currentDate].totalSeconds += timesheet.duration
                                    this.userTimesheets[key].userTimesheet[currentDate].timesheets.push(timesheet)
                                }
                            }

                            this.setCanSubmit(hasUnapproved && hasUnsubmitted)
                            this.setCanUnsubmit(hasUnapproved && hasSubmitted)
                            this.setCanApprove(hasUnapproved && hasSubmitted)
                            this.setCanUnapprove(hasApproved)

                            const uTimesheets: UserTimesheetsByPayPeriod = {}
                            uTimesheets[key] = {
                                user: userInfo[key].user,
                                userTimesheet: {},
                            }

                            let activeDate = ''

                            while (activeDate !== startDate) {
                                if (!activeDate) {
                                    activeDate = endDate
                                } else {
                                    const dd = Common.formatDate(activeDate, -1)
                                    activeDate = Common.dateOnly(dd)
                                }

                                if (!this.userTimesheets[key].userTimesheet[activeDate]) {
                                    uTimesheets[key].userTimesheet[activeDate] = {
                                        totalSeconds: 0,
                                        timesheets: [],
                                    }
                                } else {
                                    uTimesheets[key].userTimesheet[activeDate] = this.userTimesheets[key].userTimesheet[activeDate]
                                }
                            }

                            this.userTimesheets[key] = uTimesheets[key]
                        }
                    }
                }

                this.setLoading(false)
            } catch (err) {
                this.setLoading(false)
                console.log(err)
            }
        },

        async updateUserApprovedToSubmittedTo(userId: number, submitApproveType: TSheetsApproveSubmitType, submittedToDate: string, approvedToDate: string) {
            try {
                await UserService.updateApprovedToSubmittedTo(userId, approvedToDate, submittedToDate)
                window.location.reload()
            } catch (err) {
                console.log(err)
            }
        },

        setLoading(isLoading: boolean) {
            this.loading = isLoading
        },

        setCanApprove(canApprove: boolean) {
            this.canApprove = canApprove
        },

        setCanUnapprove(canUnapprove: boolean) {
            this.canUnapprove = canUnapprove
        },

        setCanSubmit(canSubmit: boolean) {
            this.canSubmit = canSubmit
        },

        setCanUnsubmit(canUnsubmit: boolean) {
            this.canUnsubmit = canUnsubmit
        },
    },
})
