import { defineStore } from 'pinia'
import StatusService from '@/services/status-service'
import { Status } from '@/types'

interface StatusState {
    statuses: Status[]
}

export const useStatusStore = defineStore('status', {
    state: (): StatusState => {
        return {
            statuses: [],
        }
    },

    actions: {
        async getStatuses () {
            try {
                this.statuses = await StatusService.getStatuses()
            } catch (err) {
                console.log(err)
            }
        },
    },
})
