import type { RouteLocationNormalized } from 'vue-router'
import Auth from './auth'

export const authGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: any
): Promise<void> => {
  if (await Auth.isAuthenticated()) {
    return next()
  }

  const { code, state } = Auth.init()

  if (code && state) {
    await Auth.getIdTokenClaims(code, state)
    return next()
  } else {
    await Auth.loginWithRedirect()
  }
}
