import { BaseHttpService } from '@/services/base-http-service'
import { AgileStoryPointLevelExample } from '@/types'

class AgileStoryPointLevelExampleService extends BaseHttpService {
    public async create(agileStoryPointLevelId: number, example: string): Promise<AgileStoryPointLevelExample> {
        const uri = '/agile-story-point-level-examples'
        const response = await this.doApiPost<AgileStoryPointLevelExample>(uri, JSON.stringify({ agileStoryPointLevelId: agileStoryPointLevelId, example: example }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async update(id: number, example: string): Promise<boolean> {
        const uri = `/agile-story-point-level-examples/${id}`
        const response = await this.doApiPut<boolean>(uri, JSON.stringify({ example }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async delete(id: number): Promise<boolean> {
        const uri = `/agile-story-point-level-examples/${id}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new AgileStoryPointLevelExampleService()
