import { BaseHttpService } from '@/services/base-http-service'

class AgileStoryPointLevelService extends BaseHttpService {
    public async updateDescription(id: number, description: string): Promise<boolean> {
        const uri = `/agile-story-point-levels/${id}`
        const response = await this.doApiPut<boolean>(uri, JSON.stringify({ description }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new AgileStoryPointLevelService()
