import { defineStore } from 'pinia'
import TicketService from '@/services/ticket-service'
import { Ticket } from '@/types'

interface TicketState {
    tickets: Ticket[]
}

export const useTicketStore = defineStore('ticket', {
    state: (): TicketState => {
        return {
            tickets: [],
        }
    },

    actions: {
        async getTicket (id: number) {
            try {
                const ticket = await TicketService.getTicket(id)

                if (ticket) {
                    this.tickets = [ticket]
                }
            } catch (err) {
                console.log(err)
            }
        },

        async getByJiraKey (jiraKey: string) {
            try {
                const ticket = await TicketService.getByJiraKey(jiraKey)

                if (ticket) {
                    this.tickets = [ticket]
                }
            } catch (err) {
                console.log(err)
            }
        },

        async getTickets (startDate: string|null = null, endDate: string|null = null, userId: number|null = null) {
            try {
                this.tickets = await TicketService.getTickets(startDate, endDate, userId)
            } catch (err) {
                console.log(err)
            }
        },
    },
})
