import RecurringTaskService from '@/services/recurring-task-service'
import { EditingTask, RecurringTask } from '@/types'
import { defineStore, storeToRefs } from 'pinia'
import Common from '@/util/common'
import TicketService from '@/services/ticket-service'
import { useGlobalStore } from '@/stores/global'

interface TaskState {
    recurringTasks: RecurringTask[]
    selectedRecurringTask: EditingTask
    labels: string[]
    createdJiraKeys: string[]
}

export const useTaskStore = defineStore('task', {
    state: (): TaskState => {
        const defaultTask: EditingTask = Common.getDefaultTask()

        return {
            recurringTasks: [],
            selectedRecurringTask: defaultTask,
            labels: [],
            createdJiraKeys: [],
        }
    },

    actions: {
        resetSelectedRecurringTask() {
            this.selectedRecurringTask = Common.getDefaultTask()
        },

        async getDistinctLabels() {
            try {
                this.labels = await TicketService.getDistinctLabels()
            } catch (err) {
                console.log(err)
            }
        },

        async getRecurringTasks () {
            try {
                this.recurringTasks = await RecurringTaskService.getRecurringTasks()
            } catch (err) {
                console.log(err)
            }
        },

        async setEditingRecurringTask(recurringTask: RecurringTask) {
            this.selectedRecurringTask = {
                id: recurringTask.id,
                projectId: recurringTask.projectId,
                epicServiceItemId: recurringTask.epicServiceItemId,
                assigneeUserId: recurringTask.assigneeUserId,
                summary: recurringTask.summary,
                issueEstimateSeconds: recurringTask.issueEstimateSeconds,
                beginDate: recurringTask.beginDate,
                frequency: recurringTask.frequency,
                lastCreatedDate: recurringTask.lastCreatedDate,
                labels: recurringTask.labels,
                daysOfWeek: recurringTask.daysOfWeek,
                occurrence: recurringTask.occurrence,
                collaborators: recurringTask.recurringTaskCollaborators || [],
            }
        },

        async saveTask(): Promise<string[]> {
            const { loggedInUser } = storeToRefs(useGlobalStore())

            let jiraIds: string[] = []

            try {
                if (this.selectedRecurringTask.id === -1) {
                    jiraIds = await RecurringTaskService.createRecurringTask(this.selectedRecurringTask, loggedInUser.value!.id)
                } else {
                    await RecurringTaskService.updateRecurringTask(this.selectedRecurringTask.id!, this.selectedRecurringTask, loggedInUser.value!.id)
                }

                await this.getRecurringTasks()

                return jiraIds
            } catch (err) {
                console.log(err)
                return []
            }
        },

        async deleteRecurringTask(id: number) {
            try {
                await RecurringTaskService.deleteRecurringTask(id)

                await this.getRecurringTasks()
            } catch (err) {
                console.log(err)
            }
        },
    },
})
