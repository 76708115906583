import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@popperjs/core'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { DraggableResizableVue, DraggableResizableContainer } from 'draggable-resizable-vue3'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { createPinia } from 'pinia'
import { PiniaSharedState } from 'pinia-shared-state'
import './assets/css/main.scss'
import Toast, { POSITION } from 'vue-toastification'
import io from 'socket.io-client'
import VueSocketIO from 'vue-3-socket.io'
import 'vue-toastification/dist/index.css'
import PageHeaderComponent from '@/components/PageHeaderComponent.vue'
import TitleComponent from '@/components/TitleComponent.vue'
/* eslint-disable */
// @ts-ignore
import ganttastic from '@infectoone/vue-ganttastic'
/* eslint-disable */
// @ts-ignore
import DraggablePanel from 'draggable-panel'
import 'draggable-panel/dist/style.css'
import { Quasar, Notify, Dialog, Loading } from 'quasar'
import 'quasar/src/css/index.sass'
library.add(fas)

const socketIOConnection = process.env.VUE_APP_WS_HOST as string

const pinia = createPinia()
pinia.use(PiniaSharedState({
    enable: true,
    initialize: true,
}))

createApp(App)
    .use(router)
    .use(new VueSocketIO({
        debug: true,
        connection: io(socketIOConnection),
    }))
    .use(Toast, {
        position: POSITION.TOP_RIGHT,
        timeout: 3500,
    })
    .use(Quasar, {
        plugins: {
            Notify,
            Dialog,
            Loading
        }
    })
    .use(ganttastic)
    .use(pinia)
    .use(DraggableResizableVue)
    .use(DraggableResizableContainer)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('Datepicker', VueDatePicker)
    .component('draggable-panel', DraggablePanel)
    .component('PageHeaderComponent', PageHeaderComponent)
    .component('TitleComponent', TitleComponent)
    .mount('#app')
