import { BaseHttpService } from '@/services/base-http-service'
import { Setting } from '@/types'

class SettingService extends BaseHttpService {
    public async getSetting(settingName: string): Promise<Setting> {
        const uri = `/settings?settingName=${settingName}`
        const response = await this.doApiGet<Setting>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getSettings(): Promise<Setting[]> {
        const uri = '/settings'
        const response = await this.doApiGet<Setting[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new SettingService()
