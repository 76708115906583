import { User } from '@/types'
import { defineStore } from 'pinia'
import UserService from '@/services/user-service'
import Auth from '@/auth/auth'

interface GlobalState {
    loggedInUser: User|null
    isAdmin: boolean|null
    isSuperAdmin: boolean|null
}

export const useGlobalStore = defineStore('global', {
    state: (): GlobalState => {
        return {
            loggedInUser: null,
            isAdmin: null,
            isSuperAdmin: null,
        }
    },

    actions: {
        async getLoggedInUser(refreshUser = false) {
            try {
                const authUser = await Auth.getUser()

                if (authUser) {
                    this.loggedInUser = await UserService.getUserByCognitoUserId(authUser.sub, refreshUser)
                }
            } catch (err) {
                this.loggedInUser = null
                console.log(err)
            }
        },

        setIsAdmin(isAdmin: boolean|null) {
            this.isAdmin = isAdmin
        },

        setIsSuperAdmin(isSuperAdmin: boolean|null) {
          this.isSuperAdmin = isSuperAdmin
        },
    },
})
