import { BaseHttpService } from '@/services/base-http-service'
import { AgileStoryPointMeasure } from '@/types'

class AgileStoryPointMeasureService extends BaseHttpService {
    public async getAgileStoryPointMeasure(id: number): Promise<AgileStoryPointMeasure> {
        const uri = `/agile-story-point-measures/${id}`
        const response = await this.doApiGet<AgileStoryPointMeasure>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getAgileStoryPointMeasures(): Promise<AgileStoryPointMeasure[]> {
        const uri = '/agile-story-point-measures'
        const response = await this.doApiGet<AgileStoryPointMeasure[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateDescription(id: number, description: string): Promise<boolean> {
        const uri = `/agile-story-point-measures/${id}`
        const response = await this.doApiPut<boolean>(uri, JSON.stringify({ description }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new AgileStoryPointMeasureService()
