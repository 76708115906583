import { ZohoDealCommission, ZohoDealAndCommissionFilter } from '@/types'
import { BaseHttpService } from '@/services/base-http-service'

class DealCommissionService extends BaseHttpService {
    public async getDealCommission(id: number): Promise<ZohoDealCommission> {
        const uri = `/zoho-deal-commissions/${id}`
        const response = await this.doApiGet<ZohoDealCommission>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getDealCommissions(filter: ZohoDealAndCommissionFilter): Promise<ZohoDealCommission[]> {
        const uri = '/zoho-deal-commissions/list'
        const response = await this.doApiPost<ZohoDealCommission[]>(uri, JSON.stringify({ filter }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new DealCommissionService()
