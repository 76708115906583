import { BaseHttpService } from '@/services/base-http-service'
import { ResourcePlanDetail, ResourcePlanDetailUser } from '@/types'

class ResourcePlanDetailService extends BaseHttpService {
    public async addResourcePlanDetail(resourcePlanDetail: Partial<ResourcePlanDetail>): Promise<ResourcePlanDetail> {
        const partialResourcePlanDetail = this.getPartialResourcePlanDetailObject(resourcePlanDetail)

        const uri = '/resource-plan-details'
        const response = await this.doApiPost<ResourcePlanDetail>(uri, JSON.stringify({ partialResourcePlanDetail }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateResourcePlanDetail(resourcePlanDetail: Partial<ResourcePlanDetail>): Promise<ResourcePlanDetail> {
        const partialResourcePlanDetail = this.getPartialResourcePlanDetailObject(resourcePlanDetail)

        const uri = `/resource-plan-details/${resourcePlanDetail.id}`
        const response = await this.doApiPut<ResourcePlanDetail>(uri, JSON.stringify({ partialResourcePlanDetail }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteSupportPlanDetailObject(id: number): Promise<boolean> {
        const uri = `/resource-plan-details/${id}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createResourcePlanDetailUser(resourcePlanDetailId: number, userId: number): Promise<ResourcePlanDetailUser> {
        const uri = `/resource-plan-details/${resourcePlanDetailId}/create-user`
        const response = await this.doApiPost<ResourcePlanDetailUser>(uri, JSON.stringify({ userId }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteResourcePlanDetailUser(resourcePlanDetailId: number, userId: number): Promise<boolean> {
        const uri = `/resource-plan-details/${resourcePlanDetailId}/delete-user?userId=${userId}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public getPartialResourcePlanDetailObject (resourcePlanDetail: Partial<ResourcePlanDetail>) {
        return {
            epicId: resourcePlanDetail.epicId,
            resourcePlanType: resourcePlanDetail.resourcePlanType,
            budgetSeconds: resourcePlanDetail.budgetSeconds,
            budgetDollars: resourcePlanDetail.budgetDollars,
            resourcePlanDetailEpicServiceItems: resourcePlanDetail.resourcePlanDetailEpicServiceItems,
            userId: resourcePlanDetail.userId,
            name: resourcePlanDetail.name,
            active: resourcePlanDetail.active,
            enabledUsersOnly: resourcePlanDetail.enabledUsersOnly,
        }
    }
}

export default new ResourcePlanDetailService()
