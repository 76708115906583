<template>
  <div class="row left-align main-header">
    <div class="col-auto">
      Jira/QuickBooks Relationship
    </div>
  </div>
  <div class="row left-align" Style="margin-left: 10px; margin-right: 10px;">
    <table class="table table-sm">
      <thead style="font-weight: bold; text-decoration: underline;">
      <th scope="col">
        Jira
      </th>
      <th scope="col">
        QuickBooks
      </th>
      <th scope="col">
        QuickBooks Time
      </th>
      </thead>

      <tbody>
      <tr>
        <td style="width: 20%; padding-left: 0;">
          Project
        </td>
        <td style="width: 20%; padding-left: 0;">
          Customer
        </td>
        <td colspan="3" style="width: 60%; padding-left: 0;">
          Job Code
        </td>
      </tr>
      <tr>
        <td style="width: 20%; padding-left: 0;">
          Epic
        </td>
        <td style="width: 20%; padding-left: 0;">
          Project
        </td>
        <td colspan="3" style="width: 60%; padding-left: 0;">
          Job Code
        </td>
      </tr>
      <tr>
        <td style="width: 20%; padding-left: 0;">
          Service Item
        </td>
        <td style="width: 20%; padding-left: 0;">
          Service Item
        </td>
        <td colspan="3" style="width: 60%; padding-left: 0;">
          Service Item
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">

</script>

<style lang="scss" scoped>

</style>
