import { BaseHttpService } from '@/services/base-http-service'
import { Issue, IssueGroup, JiraUser } from '@/types'

class JiraService extends BaseHttpService {
    public async getUser(jiraId: string): Promise<JiraUser> {
        const uri = `/jira/users/${jiraId}`
        const response = await this.doApiGet<JiraUser>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createUser(user: { emailAddress: string; products: string[] }) {
        const uri = '/jira/users'
        const response = await this.doApiPost(uri, JSON.stringify(user))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async addUserToGroups(userId: string, groups: string[]) {
        const uri = `/jira/users/${userId}/add-to-groups`
        const response = await this.doApiPost(uri, JSON.stringify(groups))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async removeUserFromGroups(userId: string, groups: string[]) {
        const uri = `/jira/users/${userId}/remove-from-groups`
        const response = await this.doApiDelete(uri, JSON.stringify(groups))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getIssue(issueKey: string) {
        const uri = `/jira/issues/${issueKey}`
        const response = await this.doApiGet(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getIssues(issueGroup: IssueGroup, startDate = '', endDate = '') {
        const uri = `/jira/issues/?startDate=${startDate}&endDate=${endDate}&issueGroup=${issueGroup}`
        const response = await this.doApiGet(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createIssue(issue: Issue): Promise<any> {
        const uri = '/jira/issues/create'
        const response = await this.doApiPost<any>(uri, JSON.stringify({ issue }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteIssue(issueKey: string) {
        const uri = `/jira/issues/${issueKey}`
        const response = await this.doApiDelete(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateTicketStatus(issueKey: string, jiraStatusId: string) {
        const uri = `/jira/issues/${issueKey}/update-status`
        const response = await this.doApiPut(uri, JSON.stringify({ jiraStatusId }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateIssueBeginAndEndDate(issueKey: string, beginDate: string, endDate: string) {
        const uri = `/jira/issues/${issueKey}/update-begin-end`
        const response = await this.doApiPut(uri, JSON.stringify({ beginDate, endDate }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateIssueRemainingEstimateSeconds(issueKey: string, originalEstimateSeconds: number, remainingEstimateSeconds: number) {
        const uri = `/jira/issues/${issueKey}/update-estimates`
        const response = await this.doApiPut(uri, JSON.stringify({ originalEstimateSeconds, remainingEstimateSeconds }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async dailyUpdate(startDate: string, endDate: string): Promise<boolean> {
        const uri = '/jira/daily-update'
        const response = await this.doApiPost<boolean>(uri, JSON.stringify({ startDate: startDate, endDate: endDate }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createScrum(): Promise<boolean> {
        const uri = '/jira/issues/create-scrum'
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new JiraService()
