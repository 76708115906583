import { BaseHttpService } from '@/services/base-http-service'
import { Report, ReportEmail } from '@/types'

class ReportService extends BaseHttpService {
    public async getReportByName(reportName: number|null): Promise<Report> {
        const uri = `/reports?reportName=${reportName}`
        const response = await this.doApiGet<Report>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getReports(inAppSelectionOnly: boolean): Promise<Report[]> {
        const uri = `/reports${inAppSelectionOnly ? '?inAppSelectionOnly=true' : ''}`
        const response = await this.doApiGet<Report[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateReport(partialReport: Partial<Report>): Promise<Report> {
        const uri = `/reports/${partialReport.id}`
        const response = await this.doApiPut<Report>(uri, JSON.stringify({ partialReport }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async addReportEmail(partialReportEmail: Partial<ReportEmail>): Promise<ReportEmail> {
        const uri = '/reports/report-emails'
        const response = await this.doApiPost<ReportEmail>(uri, JSON.stringify({ partialReportEmail }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateReportEmail(partialReportEmail: Partial<ReportEmail>): Promise<ReportEmail> {
        const uri = `/reports/report-emails/${partialReportEmail.id}`
        const response = await this.doApiPut<ReportEmail>(uri, JSON.stringify({ partialReportEmail }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteReportEmail(id: number): Promise<boolean> {
        const uri = `/reports/report-emails/${id}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new ReportService()
