import { BaseHttpService } from '@/services/base-http-service'
import { EditingWorklog, NewWorklog } from '@/types'

class ActivityTimelineService extends BaseHttpService {
    public async createWorklog(newWorklog: NewWorklog): Promise<boolean> {
        const uri = '/activity-timeline'
        const response = await this.doApiPost<boolean>(uri, JSON.stringify({ newWorklog }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateWorklog(worklogId: number, updatedWorklog: EditingWorklog): Promise<boolean> {
        const uri = `/activity-timeline/${worklogId}`
        const response = await this.doApiPut<boolean>(uri, JSON.stringify({ updatedWorklog }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteWorklog(worklogId: number): Promise<boolean> {
        const uri = `/activity-timeline/${worklogId}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new ActivityTimelineService()
