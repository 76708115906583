import { defineStore } from 'pinia'
import { BudgetInformation, Option, PotentialFutureTask, Project, Sprint } from '@/types'
import ProjectService from '@/services/project-service'
import SprintService from '@/services/sprint-service'
import PotentialFutureTaskService from '@/services/potential-future-task-service'
import Common from '@/util/common'

interface ProjectStatusState {
    loading: boolean
    budgetInformation: BudgetInformation
    projectWithBacklogTickets: Project|null
    sprints: Sprint[]
    sprintsDropdown: Option[]
    potentialFutureTasks: PotentialFutureTask[]
    jiraBaseUrl: string
}

export const useProjectStatusStore = defineStore('projectStatus', {
    state: (): ProjectStatusState => {
        return {
            loading: true,
            budgetInformation: {},
            projectWithBacklogTickets: null,
            sprints: [],
            sprintsDropdown: [],
            potentialFutureTasks: [],
            jiraBaseUrl: '',
        }
    },

    actions: {
        async getProjectSprintsAndBacklog(projectId: number, month: number, year: number) {
            try {
                const projectData = await ProjectService.getProjectSprintsAndBacklog(projectId, month, year)
                this.budgetInformation = projectData.budgetInformation
                this.projectWithBacklogTickets = projectData.projectWithBacklogTickets
                this.sprints = projectData.sprints
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async getPotentialFutureTasks(projectId: number) {
          try {
              this.potentialFutureTasks = await PotentialFutureTaskService.getPotentialFutureTasks(projectId)
          } catch (err) {
              console.log(err)
          }
        },

        async createPotentialFutureTask(potentialFutureTask: Partial<PotentialFutureTask>) {
          try {
              await PotentialFutureTaskService.createPotentialFutureTask(potentialFutureTask)
          } catch (err) {
              console.log(err)
          }
        },

        async updatePotentialFutureTask(id: number, potentialFutureTask: Partial<PotentialFutureTask>) {
            try {
                await PotentialFutureTaskService.updatePotentialFutureTask(id, potentialFutureTask)
            } catch (err) {
                console.log(err)
            }
        },

        async updatePotentialFutureTasks(potentialFutureTasks: Partial<PotentialFutureTask>[]) {
            try {
                await PotentialFutureTaskService.updatePotentialFutureTasks(potentialFutureTasks)
            } catch (err) {
                console.log(err)
            }
        },

        async deletePotentialFutureTask(id: number) {
            try {
                await PotentialFutureTaskService.deletePotentialFutureTask(id)
            } catch (err) {
                console.log(err)
            }
        },

        async getOpenSprints() {
            const sprints = await SprintService.getSprints(true)
            if (sprints?.length) {
                this.sprintsDropdown = [{
                    text: 'Select backlog/sprint location',
                    value: '',
                }, {
                    text: 'Backlog',
                    value: {
                        id: -1,
                    } as Partial<Sprint>,
                }]
                const options = Common.getSelectOptions(sprints, ['name'], '')

                this.sprintsDropdown = this.sprintsDropdown.concat(options)
            }
        },

        async getJiraBaseUrl () {
            this.jiraBaseUrl = await Common.getJiraBaseUrl()
        },
    },
})
