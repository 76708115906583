import { BaseHttpService } from '@/services/base-http-service'
import { ProjectServiceItem } from '@/types'

class ProjectServiceItemService extends BaseHttpService {
    public async createProjectServiceItem(projectServiceItem: Partial<ProjectServiceItem>): Promise<ProjectServiceItem> {
        const uri = '/project-service-items'
        const response = await this.doApiPost<ProjectServiceItem>(uri, JSON.stringify({ data: projectServiceItem }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteProjectServiceItem(id: number): Promise<boolean> {
        const uri = `/project-service-items/${id}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async loadProjectServiceItems(): Promise<boolean> {
        const uri = '/project-service-items/load'
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new ProjectServiceItemService()
