import EpicService from '@/services/epic-service'
import SupportPlanDetailService from '@/services/support-plan-detail-service'
import ResourcePlanDetailService from '@/services/resource-plan-detail-service'
import { Epic, RecordStatus, ResourcePlanDetail, SupportPlanDetail, UserResourcePlanEnabledStatus,
    UserSupportPlanEnabledStatus } from '@/types'
import { defineStore } from 'pinia'
import { toRaw } from 'vue'
import Common from '@/util/common'

interface EpicState {
    loading: boolean
    epics: Epic[]
    letters: string[]
    anchors: string[]
    epicStatuses: {
        [key: number]: string[]
    }
    resourcePlanDetailStatuses: {
        [key: number]: {
            [key: number]: {
                needsRefreshing: boolean
                statuses: string[]
            }
        }
    }
    supportPlanDetailStatuses: {
        [key: number]: {
            [key: number]: {
                needsRefreshing: boolean
                statuses: string[]
            }
        }
    }
    userResourcePlanEnabledStatuses: UserResourcePlanEnabledStatus
    userSupportPlanEnabledStatuses: UserSupportPlanEnabledStatus
    recordStatus: RecordStatus
}

export const useEpicStore = defineStore('epic', {
    state: (): EpicState => {
        return {
            loading: true,
            epics: [],
            letters: [],
            anchors: [],
            epicStatuses: {},
            resourcePlanDetailStatuses: {},
            supportPlanDetailStatuses: {},
            userResourcePlanEnabledStatuses: {},
            userSupportPlanEnabledStatuses: {},
            recordStatus: RecordStatus.Active,
        }
    },

    actions: {
        async getEpic (id: number) {
            try {
                const epic = await EpicService.getEpic(id, true)

                if (epic) {
                    this.epics = [epic]
                }
            } catch (err) {
                console.log(err)
            }
        },

        async getEpics () {
            try {
                this.epics = await EpicService.getEpics(this.recordStatus, true)
                await this.getEpicStatuses()
                this.loading = false
            } catch (err) {
                console.log(err)
            }
        },

        async setRecordStatus (recordStatus: RecordStatus) {
            this.recordStatus = recordStatus
        },

        async getEpicStatuses() {
            try {
                for (const epic of this.epics) {
                    if (epic.endDate) {
                        const information = await EpicService.epicResourceSupportPlanStatus(epic.id, null, null, null, null)
                        this.epicStatuses[epic.id] = information?.length ? information.map(i => i.message) : []
                    }
                }
            } catch (err) {
                console.log(err)
            }
        },

        getUserResourceAndSupportPlanEnabledStatuses(userId: number) {
            for (let epicIndex = 0; epicIndex < this.epics.length; epicIndex++) {
                const epic = this.epics[epicIndex]

                if (epic.resourcePlanDetails?.length) {
                    if (!this.userResourcePlanEnabledStatuses[epicIndex] || !Object.keys(this.userResourcePlanEnabledStatuses[epicIndex]).length) {
                        this.userResourcePlanEnabledStatuses[epicIndex] = {}
                    }

                    for (let resourcePlanIndex = 0; resourcePlanIndex < epic.resourcePlanDetails.length; resourcePlanIndex++) {
                        const resourcePlan = epic.resourcePlanDetails[resourcePlanIndex]

                        this.userResourcePlanEnabledStatuses[epicIndex][resourcePlanIndex] =
                            !!(resourcePlan.users?.length && resourcePlan.users.findIndex(u => u.id === userId) !== -1)
                    }
                }

                if (epic.supportPlanDetails?.length) {
                    if (!this.userSupportPlanEnabledStatuses[epicIndex] || !Object.keys(this.userSupportPlanEnabledStatuses[epicIndex]).length) {
                        this.userSupportPlanEnabledStatuses[epicIndex] = {}
                    }

                    for (let supportPlanIndex = 0; supportPlanIndex < epic.supportPlanDetails.length; supportPlanIndex++) {
                        const supportPlan = epic.supportPlanDetails[supportPlanIndex]

                        this.userSupportPlanEnabledStatuses[epicIndex][supportPlanIndex] =
                            !!(supportPlan.users?.length && supportPlan.users.findIndex(u => u.id === userId) !== -1)
                    }
                }
            }
        },

        getLettersAndAnchors() {
            const response = Common.getLettersAndAnchors(this.epics, 'name')

            this.letters = response.letters
            this.anchors = response.anchors
        },

        async addResourcePlanDetail (resourcePlanDetail: Partial<ResourcePlanDetail>, epicIndex: number) {
            try {
                if (!this.epics[epicIndex].resourcePlanDetails) {
                    this.epics[epicIndex]['resourcePlanDetails'] = []
                }

                const newResourcePlanDetail = await ResourcePlanDetailService.addResourcePlanDetail(toRaw(resourcePlanDetail))

                if (newResourcePlanDetail) {
                    this.epics[epicIndex].resourcePlanDetails!.push(newResourcePlanDetail)
                    return {
                        newResourcePlanDetail: newResourcePlanDetail,
                        newResourcePlanDetailIndex: this.epics[epicIndex].resourcePlanDetails!.length - 1
                    }
                }

                return {
                    newResourcePlanDetail: null,
                    newResourcePlanDetailIndex: -1
                }
            } catch (err) {
                console.log(err)
            }
        },

        async addSupportPlanDetail (supportPlanDetail: Partial<SupportPlanDetail>, epicIndex: number) {
            try {
                if (!this.epics[epicIndex].supportPlanDetails) {
                    this.epics[epicIndex]['supportPlanDetails'] = []
                }

                const newSupportPlanDetail = await SupportPlanDetailService.addSupportPlanDetail(toRaw(supportPlanDetail))

                if (newSupportPlanDetail) {
                    this.epics[epicIndex].supportPlanDetails!.push(newSupportPlanDetail)
                    return {
                        newSupportPlanDetail: newSupportPlanDetail,
                        newSupportPlanDetailIndex: this.epics[epicIndex].supportPlanDetails!.length - 1
                    }
                }

                return {
                    newSupportPlanDetail: null,
                    newSupportPlanDetailIndex: -1
                }
            } catch (err) {
                console.log(err)
            }
        },

        async updateResourcePlanDetail (resourcePlanDetail: Partial<ResourcePlanDetail>, epicIndex: number, resourcePlanIndex: number) {
            try {
                if (this.epics[epicIndex].resourcePlanDetails?.length) {
                    this.epics[epicIndex].resourcePlanDetails![resourcePlanIndex] = await ResourcePlanDetailService.updateResourcePlanDetail(toRaw(resourcePlanDetail))
                }
            } catch (err) {
                console.log(err)
            }
        },

        async updateSupportPlanDetail (supportPlanDetail: Partial<SupportPlanDetail>, epicIndex: number, supportPlanIndex: number) {
            try {
                if (this.epics[epicIndex].supportPlanDetails?.length) {
                    this.epics[epicIndex].supportPlanDetails![supportPlanIndex] = await SupportPlanDetailService.updateSupportPlanDetail(toRaw(supportPlanDetail))
                }
            } catch (err) {
                console.log(err)
            }
        },

        async deleteResourcePlanDetail (id: number, epicIndex: number, resourcePlanIndex: number) {
            try {
                await ResourcePlanDetailService.deleteSupportPlanDetailObject(id)
                if (this.epics[epicIndex].resourcePlanDetails?.length) {
                    this.epics[epicIndex].resourcePlanDetails!.splice(resourcePlanIndex, 1)
                }
            } catch (err) {
                console.log(err)
            }
        },

        async deleteSupportPlanDetail (id: number, epicIndex: number, supportPlanIndex: number) {
            try {
                await SupportPlanDetailService.deleteSupportPlanDetail(id)
                if (this.epics[epicIndex].supportPlanDetails?.length) {
                    this.epics[epicIndex].supportPlanDetails!.splice(supportPlanIndex, 1)
                }
            } catch (err) {
                console.log(err)
            }
        },

        async createResourcePlanDetailUser (resourcePlanDetailId: number, userId: number) {
            await ResourcePlanDetailService.createResourcePlanDetailUser(resourcePlanDetailId, userId)
        },

        async deleteResourcePlanDetailUser (resourcePlanDetailId: number, userId: number) {
            await ResourcePlanDetailService.deleteResourcePlanDetailUser(resourcePlanDetailId, userId)
        },

        async createSupportPlanDetailUser (supportPlanDetailId: number, userId: number) {
            await SupportPlanDetailService.createSupportPlanDetailUser(supportPlanDetailId, userId)
        },

        async deleteSupportPlanDetailUser (supportPlanDetailId: number, userId: number) {
            await SupportPlanDetailService.deleteSupportPlanDetailUser(supportPlanDetailId, userId)
        },
    },
})
