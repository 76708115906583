import { BaseHttpService } from '@/services/base-http-service'
import { AlertLog } from '@/types'

class AlertLogService extends BaseHttpService {
    public async getAlertLogs(startDate: string, endDate: string): Promise<AlertLog[]> {
        const uri = `/alert-logs?startDate=${startDate}&endDate=${endDate}`
        const response = await this.doApiGet<AlertLog[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new AlertLogService()
