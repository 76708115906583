import { BaseHttpService } from '@/services/base-http-service'
import { QuickBooksCustomer, QuickBooksEmployee, UpdateQuickBooksService } from '@/types'

class QuickBooksService extends BaseHttpService {
    public async getEmployee(id: string): Promise<QuickBooksEmployee> {
        const uri = `/quick-books/employees/${id}`
        const response = await this.doApiGet<QuickBooksEmployee>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createEmployee(employee: Partial<QuickBooksEmployee>): Promise<QuickBooksEmployee> {
        const uri = '/quick-books/employees'
        const response = await this.doApiPost<QuickBooksEmployee>(uri, JSON.stringify(employee))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateEmployee(quickBooksId: string, employee: Partial<QuickBooksEmployee>): Promise<QuickBooksEmployee> {
        const uri = `/quick-books/employees/${quickBooksId}`
        const response = await this.doApiPut<QuickBooksEmployee>(uri, JSON.stringify(employee))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getCustomer(id: string): Promise<QuickBooksCustomer> {
        const uri = `/quick-books/customers/${id}`
        const response = await this.doApiGet<QuickBooksCustomer>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getCustomerByName(name: string): Promise<QuickBooksCustomer | null> {
        const uri = `/quick-books/customers?name=${name}`
        const response = await this.doApiGet<QuickBooksCustomer>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateQuickBooksService(quickBooksService: UpdateQuickBooksService): Promise<boolean> {
        const uri = '/quick-books/services'
        const response = await this.doApiPut<boolean>(uri, JSON.stringify({ data: quickBooksService }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async refreshQuickBooksTokens() {
        const uri = '/external/quick-books/authorization/refresh-tokens'
        const response = await this.doApiPut(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new QuickBooksService()
