import { ZohoClient, RecordStatus } from '@/types'
import { BaseHttpService } from '@/services/base-http-service'

class ClientService extends BaseHttpService {
    public async getClient(id: number): Promise<ZohoClient> {
        const uri = `/zoho-clients/${id}`
        const response = await this.doApiGet<ZohoClient>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getClients(recordStatus = RecordStatus.Active): Promise<ZohoClient[]> {
        const uri = `/zoho-clients?recordStatus=${recordStatus}`
        const response = await this.doApiGet<ZohoClient[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createClient(client: Partial<ZohoClient>): Promise<ZohoClient> {
        const uri = '/zoho-clients'
        const response = await this.doApiPost<ZohoClient>(uri, JSON.stringify(client))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateClient(id: number, client: Partial<ZohoClient>): Promise<ZohoClient> {
        const uri = `/zoho-clients/${id}`
        const response = await this.doApiPut<ZohoClient>(uri, JSON.stringify(client))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async load(): Promise<string> {
        const uri = '/zoho-clients/load'
        const response = await this.doApiPost<string>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new ClientService()
