import { BaseHttpService } from '@/services/base-http-service'
import { AgileStoryPointMeasureExample } from '@/types'

class AgileStoryPointMeasureExampleService extends BaseHttpService {
    public async create(agileStoryPointMeasureId: number, example: string): Promise<AgileStoryPointMeasureExample> {
        const uri = '/agile-story-point-measure-examples'
        const response = await this.doApiPost<AgileStoryPointMeasureExample>(uri, JSON.stringify({ agileStoryPointMeasureId: agileStoryPointMeasureId, example: example }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async update(id: number, example: string): Promise<boolean> {
        const uri = `/agile-story-point-measure-examples/${id}`
        const response = await this.doApiPut<boolean>(uri, JSON.stringify({ example }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async delete(id: number): Promise<boolean> {
        const uri = `/agile-story-point-measure-examples/${id}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new AgileStoryPointMeasureExampleService()
