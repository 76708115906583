import { defineStore } from 'pinia'
import JeopardyGameService from '@/services/jeopardy-game-service'
import { JeopardyCategory, JeopardyGame, JeopardyQuestion, JeopardyQuestionTeam, JeopardyTeam } from '@/types'
import io from 'socket.io-client'

interface JeopardyGameState {
    jeopardyGame: JeopardyGame|null
    questions: any
    currentQuestion: JeopardyQuestion|null
    currentQuestionResponses: number
    jeopardyTeam: JeopardyTeam|null
    answeredTeams: any
    hasBuzzedInTeam: boolean
    loading: boolean
}

export const useJeopardyGameStore = defineStore('jeopardyGame', {
    state: (): JeopardyGameState => {
        return {
            jeopardyGame: null,
            questions: {},
            currentQuestion: null,
            currentQuestionResponses: 0,
            jeopardyTeam: null,
            answeredTeams: {},
            hasBuzzedInTeam: false,
            loading: true,
        }
    },

    actions: {
        async loadJeopardyGame() {
            this.questions = {}
            this.jeopardyGame = await JeopardyGameService.getGame(12)
            if (this.jeopardyGame?.jeopardyCategories?.length && this.jeopardyGame.jeopardyCategories[0].jeopardyQuestions?.length) {
                for (const category of this.jeopardyGame.jeopardyCategories) {
                    let questionNum = 0

                    for (const question of category.jeopardyQuestions!) {
                        if (!this.questions[questionNum]) {
                            this.questions[questionNum] = []
                        }

                        this.questions[questionNum++].push(question)
                    }
                }
            }
        },

        async updateJeopardyGame(id: number, game: Partial<JeopardyGame>) {
            await JeopardyGameService.updateGame(id, game)
            await this.loadJeopardyGame()
        },

        async updateJeopardyCategory(id: number, category: Partial<JeopardyCategory>) {
            await JeopardyGameService.updateCategory(id, category)
        },

        async updateJeopardyTeam(id: number, team: Partial<JeopardyTeam>) {
          await JeopardyGameService.updateTeam(id, team)
        },

        async createJeopardyQuestionTeam(jeopardyQuestionTeam: Partial<JeopardyQuestionTeam>) {
            await JeopardyGameService.createJeopardyQuestionTeam(jeopardyQuestionTeam)
        },

        async updateJeopardyQuestion(id: number, question: Partial<JeopardyQuestion>) {
            await JeopardyGameService.updateQuestion(id, question)
        },

        setLoading(isLoading: boolean) {
            this.loading = isLoading
        },

        async setJeopardyTeam(jeopardyTeam: JeopardyTeam|null) {
            this.jeopardyTeam = jeopardyTeam
        },

        async setCurrentQuestion(question: JeopardyQuestion|null) {
            if (question) {
                await JeopardyGameService.updateQuestion(question.id, { isCurrentQuestion: true })
            } else if (this.currentQuestion) {
                await JeopardyGameService.updateQuestion(this.currentQuestion.id, { isCurrentQuestion: false })
            }

            this.currentQuestion = question
            this.setCurrentQuestionResponses(true)
            this.answeredTeams = {}

            const socket = io(process.env.VUE_APP_WS_HOST as string)

            if (question) {
                if (question?.isDailyDouble && this.jeopardyGame?.choosingJeopardyTeamId) {
                    await this.updateJeopardyQuestion(question.id, { dailyDoubleJeopardyTeamId: this.jeopardyGame.choosingJeopardyTeamId })
                    await this.updateJeopardyGame(this.jeopardyGame!.id, { isDailyDouble: true, buzzedInJeopardyTeamId: this.jeopardyGame.choosingJeopardyTeamId })
                    this.hasBuzzedInTeam = true
                    socket.emit('isDailyDouble', { jeopardyGameId: this.jeopardyGame.id, jeopardyTeamId: this.jeopardyGame.choosingJeopardyTeamId, isDailyDouble: true })
                } else {
                    socket.emit('lockBuzzer', { jeopardyGameId: this.jeopardyGame!.id, lock: false })
                }
            } else {
                socket.emit('lockBuzzer', { jeopardyGameId: this.jeopardyGame!.id, lock: true })
            }
        },

        setCurrentQuestionResponses(reset = false) {
            if (!reset) {
                this.currentQuestionResponses ++
            } else {
                this.currentQuestionResponses = 0
            }
        },

        addAnsweredTeam(teamId: number) {
            this.answeredTeams[teamId] = true
        },
    },
})
