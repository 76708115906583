import StickyNoteService from '@/services/sticky-note-service'
import StickyNoteSettingService from '@/services/user-sticky-note-setting-service'
import { StickyNote, StickyNoteStatus, UserStickyNoteSetting } from '@/types'
import { defineStore } from 'pinia'
import Common from '@/util/common'

interface StickyNoteState {
    stickyNotes: StickyNote[]
    filteredStickyNotes: StickyNote[]
    stickyNoteSettings: UserStickyNoteSetting|null
    jiraBaseUrl: string
}

export const useStickyNoteStore = defineStore('stickyNote', {
    state: (): StickyNoteState => {
        return {
            stickyNotes: [],
            filteredStickyNotes: [],
            stickyNoteSettings: null,
            jiraBaseUrl: '',
        }
    },

    actions: {
        async getStickyNotes (userId: number) {
            try {
                this.stickyNotes = await StickyNoteService.getStickyNotes(userId)
            } catch (err) {
                console.log(err)
            }
        },

        setFilteredStickyNotes() {
            this.filteredStickyNotes = []

            if (this.stickyNoteSettings?.showToDo) {
                this.filteredStickyNotes = this.filteredStickyNotes.concat(this.stickyNotes.filter(sn => sn.status === StickyNoteStatus.ToDo))
            }

            if (this.stickyNoteSettings?.showInProgress) {
                this.filteredStickyNotes = this.filteredStickyNotes.concat(this.stickyNotes.filter(sn => sn.status === StickyNoteStatus.InProgress))
            }

            if (this.stickyNoteSettings?.showDone) {
                this.filteredStickyNotes = this.filteredStickyNotes.concat(this.stickyNotes.filter(sn => sn.status === StickyNoteStatus.Done))
            }

            if (this.stickyNoteSettings?.showInJira) {
                this.filteredStickyNotes = this.filteredStickyNotes.concat(this.stickyNotes.filter(sn => sn.status === StickyNoteStatus.InJira))
            }
        },

        async getStickyNoteSettings (userId: number) {
            try {
                let noteSettings = await StickyNoteSettingService.getByUserId(userId)

                if (!noteSettings) {
                    noteSettings = await StickyNoteSettingService.createStickyNoteSettings(userId)
                }

                this.stickyNoteSettings = noteSettings

                this.setFilteredStickyNotes()
            } catch (err) {
                console.log(err)
            }
        },

        async updateStickyNoteSettings (userId: number) {
            try {
                if (this.stickyNoteSettings) {
                    await StickyNoteSettingService.updateStickyNoteSettings(this.stickyNoteSettings.id, this.stickyNoteSettings)
                }

                await this.getStickyNotes(userId)
                this.setFilteredStickyNotes()
            } catch (err) {
                console.log(err)
            }
        },

        async createStickyNote (stickyNote: Partial<StickyNote>, userId: number): Promise<{ stickyNote: StickyNote|undefined, index: number }> {
            try {
                await StickyNoteService.create(stickyNote)
                await this.getStickyNotes(userId)
                this.setFilteredStickyNotes()

                const newStickyNote = this.stickyNotes.find(sn => sn.name === stickyNote.name && sn.note === stickyNote.note)
                const newStickyNoteIndex = this.stickyNotes.findIndex(sn => sn.name === stickyNote.name && sn.note === stickyNote.note)

                await this.getStickyNotes(userId)
                this.setFilteredStickyNotes()

                return {
                    stickyNote: newStickyNote,
                    index: newStickyNoteIndex,
                }
            } catch (err) {
                console.log(err)

                return {
                    stickyNote: undefined,
                    index: -1,
                }
            }
        },

        async updateStickyNote (stickyNote: StickyNote, userId: number) {
            try {
                await StickyNoteService.update(stickyNote.id, stickyNote)

                await this.getStickyNotes(userId)
                this.setFilteredStickyNotes()
            } catch (err) {
                console.log(err)
            }
        },

        async updateStatus (id: number, currentStatus: StickyNoteStatus, direction: string, userId: number) {
            try {
                let  status = currentStatus

                if (status === StickyNoteStatus.ToDo || status === StickyNoteStatus.Done) {
                    status = StickyNoteStatus.InProgress
                } else if (direction === 'back') {
                    status = StickyNoteStatus.ToDo
                } else {
                    status = StickyNoteStatus.Done
                }

                await StickyNoteService.updateStatus(id, status)

                await this.getStickyNotes(userId)
                this.setFilteredStickyNotes()
            } catch (err) {
                console.log(err)
            }
        },

        async deleteStickyNote (id: number, userId: number) {
            try {
                await StickyNoteService.delete(id)

                await this.getStickyNotes(userId)
                this.setFilteredStickyNotes()
            } catch (err) {
                console.log(err)
            }
        },

        async getJiraBaseUrl () {
            this.jiraBaseUrl = await Common.getJiraBaseUrl()
        },
    },
})
