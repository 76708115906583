import { BaseHttpService } from '@/services/base-http-service'
import { ProjectUser } from '@/types'

class ProjectUserService extends BaseHttpService {
    public async createProjectUser(projectUser: Partial<ProjectUser>): Promise<ProjectUser> {
        const uri = '/project-users'
        const response = await this.doApiPost<ProjectUser>(uri, JSON.stringify({ data: projectUser }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteProjectUser(id: number): Promise<boolean> {
        const uri = `/project-users/${id}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async loadProjectUsers(): Promise<boolean> {
        const uri = '/project-users/load'
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new ProjectUserService()
