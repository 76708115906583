import { BaseHttpService } from '@/services/base-http-service'
import { Status } from '@/types'

class StatusService extends BaseHttpService {
    public async getStatus(id: number): Promise<Status> {
        const uri = `/statuses/${id}`
        const response = await this.doApiGet<Status>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getStatuses(): Promise<Status[]> {
        const uri = '/statuses'
        const response = await this.doApiGet<Status[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async loadStatuses(): Promise<boolean> {
        const uri = '/statuses/load'
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new StatusService()
