import ProjectService from '@/services/project-service'
import { Project, RecordStatus, User } from '@/types'
import { defineStore } from 'pinia'
import UserService from '@/services/user-service'
import Common from '@/util/common'

interface ProjectState {
    projects: Project[]
    users: User[]
    letters: string[]
    anchors: string[]
}

export const useProjectStore = defineStore('project', {
    state: (): ProjectState => {
        return {
            projects: [],
            users: [],
            letters: [],
            anchors: [],
        }
    },

    actions: {
        async getProjects (projectRecordStatus: RecordStatus, epicServiceItemRecordStatus: RecordStatus) {
            try {
                this.projects = await ProjectService.getProjects(projectRecordStatus, [], true, epicServiceItemRecordStatus)
            } catch (err) {
                console.log(err)
            }
        },

        async getUsers () {
            try {
                this.users = await UserService.getUsers('active', true)
            } catch (err) {
                console.log(err)
            }
        },

        getLettersAndAnchors() {
            const response = Common.getLettersAndAnchors(this.projects, 'name')

            this.letters = response.letters
            this.anchors = response.anchors
        },
    },
})
