import { BaseHttpService } from '@/services/base-http-service'

class AlertService extends BaseHttpService {
    public async runAlertChecks(startDate: string, endDate: string): Promise<boolean> {
        const uri = '/alerts/run-all'
        const response = await this.doApiPost<boolean>(uri, JSON.stringify({ startDate: startDate, endDate: endDate }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new AlertService()
