import { ZohoCommissionAssignments, ZohoDeal, ZohoDealAndCommissionFilter } from '@/types'
import { BaseHttpService } from '@/services/base-http-service'

class DealService extends BaseHttpService {
    public async getDeal(id: number): Promise<ZohoDeal> {
        const uri = `/zoho-deals/${id}`
        const response = await this.doApiGet<ZohoDeal>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getDeals(filter: ZohoDealAndCommissionFilter = { zohoClientId: null, commissionAssignmentUserId: null, commissionEffectiveDate: null }): Promise<ZohoDeal[]> {
        const uri = '/zoho-deals/list'
        const response = await this.doApiPost<ZohoDeal[]>(uri, JSON.stringify({ filter }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateDeal(id: number, deal: Partial<ZohoDeal>, zohoCommissionAssignments: ZohoCommissionAssignments): Promise<ZohoDeal> {
        const uri = `/zoho-deals/${id}`
        const response = await this.doApiPut<ZohoDeal>(uri, JSON.stringify({ deal, zohoCommissionAssignments }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new DealService()
