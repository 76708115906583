import { SecureNote } from '@/types'
import { defineStore, storeToRefs } from 'pinia'
import { useGlobalStore } from '@/stores/global'
import SecureNoteService from '@/services/secure-note-service'

interface SecureNoteState {
    secureNotes: SecureNote[]
    selectedSecureNote: SecureNote
}

export const useSecureNoteStore = defineStore('secure-note', {
    state: (): SecureNoteState => {
        return {
            secureNotes: [],
            selectedSecureNote: {
                type: 'login',
                title: '',
                createdById: '',
                createdByName: '',
                createdByEmail: '',
                token: '',
                instructions: '',
                receiverEmail: '',
                username: '',
                password: '',
                website: '',
                clientId: '',
                publicKey: '',
                privateKey: '',
                note: '',
                expireTime: '0',
                expireDateTime: '',
            },
        }
    },

    actions: {
        async getSecureNote(token: string) {
          try {
              this.selectedSecureNote = await SecureNoteService.getSecureNote(token)
          } catch (err) {
              console.log(err)
          }
        },

        async getSecureNotes() {
            const { loggedInUser, isSuperAdmin } = storeToRefs(useGlobalStore())

            try {
                this.secureNotes = await SecureNoteService.getSecureNotes(isSuperAdmin.value ? null : loggedInUser.value!.id)
            } catch (err) {
                console.log(err)
            }
        },

        async createSecureNote() {
            const { loggedInUser } = storeToRefs(useGlobalStore())

            try {
                const currDate = new Date()

                this.selectedSecureNote.expireDateTime = new Date(currDate.setTime(currDate.getTime() + parseInt(this.selectedSecureNote.expireTime) * 1000)).toLocaleString()
                this.selectedSecureNote.createdById = loggedInUser.value!.id.toString()

                await SecureNoteService.createSecureNote(this.selectedSecureNote)

                await this.getSecureNotes()
            } catch (err) {
                console.log(err)
            }
        },

        async updateSecureNote(sendSecureLink = false) {
            try {
                await SecureNoteService.updateSecureNote(this.selectedSecureNote, sendSecureLink)

                await this.getSecureNotes()
            } catch (err) {
                console.log(err)
            }
        },

        async deleteSecureNote(token: string) {
            try {
                await SecureNoteService.deleteSecureNote(token)

                await this.getSecureNotes()
            } catch (err) {
                console.log(err)
            }
        },
    },
})
