import { BaseHttpService } from '@/services/base-http-service'

class ZohoService extends BaseHttpService {
    async getFields(moduleName: string) {
        const uri = `/zoho/fields/${moduleName}`
        const response = await this.doApiGet(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async setNewAccessToken(code: string): Promise<boolean> {
        const uri = '/zoho/access-token'
        const response = await this.doApiPost<boolean>(uri, JSON.stringify({ code }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new ZohoService()
