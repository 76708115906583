import { BaseHttpService } from '@/services/base-http-service'
import { Customer } from '@/types'

class CustomerService extends BaseHttpService {
    public async getCustomers(): Promise<Customer[]> {
        const uri = '/customers'
        const response = await this.doApiGet<Customer[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async loadCustomers(): Promise<boolean> {
        const uri = '/customers/load'
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new CustomerService()
