import { User } from '@/types'
import { BaseHttpService } from '@/services/base-http-service'

class UserService extends BaseHttpService {
    public async getUser(id: number, refreshUser = false): Promise<User> {
        const uri = `/users/${id}?refreshUser=${refreshUser}`
        const response = await this.doApiGet<User>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getUserByEmail(email: string): Promise<User> {
        const uri = `/users?email=${email}`
        const response = await this.doApiGet<User>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getUserByCognitoUserId(cognitoUserId: string, refreshUser = false): Promise<User> {
        const uri = `/users?cognitoUserId=${cognitoUserId}&refreshUser=${refreshUser}`
        const response = await this.doApiGet<User>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateApprovedToSubmittedTo(userId: number, approvedTo: string, submittedTo: string): Promise<User> {
        const data = {
            approvedTo: approvedTo,
            submittedTo: submittedTo,
        }

        const uri = `/users/${userId}/approved-submitted-update`
        const response = await this.doApiPut<User>(uri, JSON.stringify(data))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getUsers(recordStatus: string, zeytechEmployeesOnly = false): Promise<User[]> {
        const uri = `/users?recordStatus=${recordStatus}&zeytechEmployeesOnly=${zeytechEmployeesOnly}`
        const response = await this.doApiGet<User[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getZeytechUser(): Promise<User> {
        const uri = '/zeytech-user'
        const response = await this.doApiGet<User>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getZeytechUserId(): Promise<number> {
        const uri = '/zeytech-user-id'
        const response = await this.doApiGet<number>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createUser(user: Partial<User>): Promise<User> {
        const uri = '/users'
        const response = await this.doApiPost<User>(uri, JSON.stringify(user))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateUser(id: number, user: Partial<User>): Promise<User> {
        const uri = `/users/${id}`
        const response = await this.doApiPut<User>(uri, JSON.stringify(user))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async loadUsers(): Promise<boolean> {
        const uri = '/users/load'
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new UserService()
