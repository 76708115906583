import { BaseHttpService } from '@/services/base-http-service'

class ImportService extends BaseHttpService {
    public async processCSV(formData: FormData): Promise<any[]> {
        const uri = '/imports/process-csv-file'
        const response = await this.doApiPost<any[]>(uri, formData, false)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async uploadExcelCsvFile(data: any[], filename: string): Promise<any> {
        const uri = `/imports/${filename}`
        const response = await this.doApiPost(uri, JSON.stringify({ data }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new ImportService()
