import { BaseHttpService } from '@/services/base-http-service'
import { EpicServiceItem } from '@/types'

class EpicServiceItemService extends BaseHttpService {
    public async createEpicServiceItem(epicServiceItem: Partial<EpicServiceItem>): Promise<EpicServiceItem> {
        const uri = '/epic-service-items'
        const response = await this.doApiPost<EpicServiceItem>(uri, JSON.stringify({ data: epicServiceItem }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteEpicServiceItem(id: number): Promise<boolean> {
        const uri = `/epic-service-items/${id}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async loadEpicServiceItems(): Promise<boolean> {
        const uri = '/epic-service-items/load'
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new EpicServiceItemService()
