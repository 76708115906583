import { BaseHttpService } from '@/services/base-http-service'
import { GenericEmailInformation } from '@/types'

class EmailService extends BaseHttpService {
    public async sendEmail(emailInformation: GenericEmailInformation): Promise<boolean> {
        const uri = '/send-email'
        const response = await this.doApiPost<boolean>(uri, JSON.stringify(emailInformation))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new EmailService()
