import { BaseHttpService } from '@/services/base-http-service'
import { TimeOffType } from '@/types'

class TimeOffTypeService extends BaseHttpService {
    public async getTimeOffType(id: number): Promise<TimeOffType> {
        const uri = `/time-off-types/${id}`
        const response = await this.doApiGet<TimeOffType>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getTimeOffTypes(): Promise<TimeOffType[]> {
        const uri = '/time-off-types'
        const response = await this.doApiGet<TimeOffType[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async loadTimeOffTypes(): Promise<boolean> {
        const uri = '/time-off-types/load'
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new TimeOffTypeService()
