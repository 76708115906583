import { defineStore } from 'pinia'
import ServiceItemService from '@/services/service-item-service'
import { RecordStatus, ServiceItem } from '@/types'
import Common from '@/util/common'

interface ServiceItemState {
    serviceItems: ServiceItem[]
    letters: string[]
    anchors: string[]
}

export const useServiceItemStore = defineStore('serviceItem', {
    state: (): ServiceItemState => {
        return {
            serviceItems: [],
            letters: [],
            anchors: [],
        }
    },

    actions: {
        async getServiceItems (recordStatus: RecordStatus, onlyWithoutEpic = false) {
            try {
                this.serviceItems = await ServiceItemService.getServiceItems(recordStatus, '', onlyWithoutEpic)
            } catch (err) {
                console.log(err)
            }
        },

        getLettersAndAnchors() {
            const response = Common.getLettersAndAnchors(this.serviceItems, 'name')

            this.letters = response.letters
            this.anchors = response.anchors
        },
    },
})
