import { BaseHttpService } from '@/services/base-http-service'
import { OrganizationKeyWord } from '@/types'

class OrganizationKeywordService extends BaseHttpService {
    public async getOrganizationKeywords(): Promise<OrganizationKeyWord[]> {
        const uri = '/organization-keywords'
        const response = await this.doApiGet<OrganizationKeyWord[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new OrganizationKeywordService()
