import { BaseHttpService } from '@/services/base-http-service'
import { EpicUser } from '@/types'

class EpicUserService extends BaseHttpService {
    public async createEpicUser(epicUser: Partial<EpicUser>): Promise<EpicUser> {
        const uri = '/epic-users'
        const response = await this.doApiPost<EpicUser>(uri, JSON.stringify({ data: epicUser }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteEpicUser(id: number): Promise<boolean> {
        const uri = `/epic-users/${id}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async loadEpicUsers(): Promise<boolean> {
        const uri = '/epic-users/load'
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new EpicUserService()
