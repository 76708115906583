import downloadjs from 'downloadjs'
import Auth from '@/auth/auth'

export interface ApiError {
    code: string
    message: string
    status: number
    stack?: string
}

export interface ApiResponse<T> {
    success: boolean
    error?: ApiError
    response?: T
}

export abstract class BaseHttpService {
    protected async getToken(): Promise<string|null> {
        let token: string|null = null

        const isAuthenticated = await Auth.isAuthenticated()

        if (!isAuthenticated) {
            await Auth.logout(true)
        } else {
            token = Auth.getAccessToken()
        }

        return token
    }

    protected async handleResponse<T>(response: Response): Promise<ApiResponse<T>> {
        const result: ApiResponse<T> = {
            success: true
        }
        if (response.status === 204) {
            result.response = undefined
            return result
        }
        const json = await response.json()
        if (response.status >= 400) {
            result.success = false
            json.status = response.status
            if (response.status === 404 && json.code === 'E_MODEL_NOT_FOUND') {
                json.message = json.message.replace('E_MODEL_NOT_FOUND: ', '') + ' not found'
            }
            result.error = json
        } else {
            result.response = json
        }
        return result
    }

    protected async doApiGet<T>(uri: string, isPublic = false): Promise<ApiResponse<T>> {
        return this.doApiCall('GET', uri, null, true, isPublic)
    }

    protected async doApiPut<T>(uri: string, body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null | undefined): Promise<ApiResponse<T>> {
        return this.doApiCall('PUT', uri, body)
    }

    protected async doApiPost<T>(uri: string, body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null | undefined, includeContentType = true): Promise<ApiResponse<T>> {
        return this.doApiCall('POST', uri, body, includeContentType)
    }

    protected async doApiDelete<T>(uri: string, body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null | undefined): Promise<ApiResponse<T>> {
        return this.doApiCall('DELETE', uri, body)
    }

    protected async doApiCall<T>(method: string, uri: string, body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null | undefined, includeContentType = true, isPublic = false): Promise<ApiResponse<T>> {
        const response = await this.getResponse(uri, method, body, includeContentType, isPublic)

        return this.handleResponse(response)
    }

    public async download(uri: string, mimeType: string, body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null | undefined, isPublic = false): Promise<ApiResponse<boolean>> {
        const response = await this.getResponse(uri, 'POST', body, true, isPublic)

        if (response.status >= 400) {
            return {
                success: false,
                error: {
                    code: '',
                    message: await response.text(),
                    status: response.status
                }
            }
        } else {
            let data: any

            if (mimeType === 'text/csv') {
                data = await response.text()
            } else {
                const buffer = await response.arrayBuffer()
                data = new Blob([buffer], { type: mimeType})
            }

            const disposition = response.headers.get('content-disposition')
            const filename = disposition ? disposition.split('=')[1].replace(/"/g, '') : 'download.csv'
            downloadjs(data, filename, response.headers.get('content-type')?.split(';')[0])
            return {
                success: true,
            }
        }
    }

    public async downloadBinary(uri: string, mimeType: string, body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null | undefined, isPublic = false): Promise<ApiResponse<boolean>> {
        const headers = new Headers()
        headers.append('Content-type', 'application/json')

        if (!isPublic) {
            const token = await this.getToken()

            if (!token) {
                throw new Error('unauthorized')
            }

            headers.append('Authorization', `Bearer ${token}`)
        }

        const request = new Request(`${process.env.VUE_APP_API_HOST}${uri}`, {
            method: 'POST',
            headers,
            body,
        })
        const response = await fetch(request)
        if (response.status >= 400) {
            return {
                success: false,
                error: {
                    code: '',
                    message: await response.text(),
                    status: response.status
                }
            }
        } else {
            const buffer = await response.arrayBuffer()
            const blob = new Blob([buffer], { type: mimeType})
            const disposition = response.headers.get('content-disposition')
            const filename = disposition ? disposition.split('=')[1].replace(/"/g, '') : 'download.csv'
            downloadjs(blob, filename, response.headers.get('content-type')?.split(';')[0])
            return {
                success: true,
            }
        }
    }

    public async getResponse(uri: string, method: string, body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null | undefined, includeContentType = true, isPublic = false): Promise<Response> {
        console.log('api', method, 'call', uri)
        const headers = new Headers()
        if (includeContentType) {
            headers.append('Content-type', 'application/json')
        }

        if (!isPublic) {
            const token = await this.getToken()

            if (!token) {
                throw new Error('unauthorized')
            }

            headers.append('Authorization', `Bearer ${token}`)
        }

        const request = new Request(`${process.env.VUE_APP_API_HOST}${uri}`, {
            method: method,
            headers,
            body,
        })

        return fetch(request)
    }
}
