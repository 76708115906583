import { BaseHttpService } from '@/services/base-http-service'
import { PotentialFutureTask } from '@/types'

class PotentialFutureTaskService extends BaseHttpService {
    public async getPotentialFutureTask(id: number): Promise<PotentialFutureTask> {
        const uri = `/potential-future-tasks/${id}`
        const response = await this.doApiGet<PotentialFutureTask>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getPotentialFutureTasks(projectId: number|null = null): Promise<PotentialFutureTask[]> {
        const uri = `/potential-future-tasks${projectId ? `?projectId=${projectId}` : ''}`
        const response = await this.doApiGet<PotentialFutureTask[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createPotentialFutureTask(potentialFutureTask: Partial<PotentialFutureTask>): Promise<PotentialFutureTask> {
        const uri = '/potential-future-tasks'
        const response = await this.doApiPost<PotentialFutureTask>(uri, JSON.stringify({ data: potentialFutureTask }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updatePotentialFutureTask(id: number, potentialFutureTask: Partial<PotentialFutureTask>): Promise<PotentialFutureTask> {
        const uri = `/potential-future-tasks/${id}`
        const response = await this.doApiPut<PotentialFutureTask>(uri, JSON.stringify({ data: potentialFutureTask }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updatePotentialFutureTasks(potentialFutureTasks: Partial<PotentialFutureTask>[]): Promise<boolean> {
        const uri = '/potential-future-tasks/bulk-update'
        const response = await this.doApiPut<boolean>(uri, JSON.stringify({ data: potentialFutureTasks }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deletePotentialFutureTask(id: number): Promise<boolean> {
        const uri = `/potential-future-tasks/${id}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new PotentialFutureTaskService()
