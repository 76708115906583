import { BaseHttpService } from '@/services/base-http-service'
import { EditingTask, RecurringTask } from '@/types'

class RecurringTaskService extends BaseHttpService {
    public async getRecurringTask(id: number): Promise<RecurringTask> {
        const uri = `/recurring-tasks/${id}`
        const response = await this.doApiGet<RecurringTask>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getRecurringTasks(): Promise<RecurringTask[]> {
        const uri = '/recurring-tasks'
        const response = await this.doApiGet<RecurringTask[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createRecurringTask(recurringTask: EditingTask, userId = -1): Promise<string[]> {
        const uri = '/recurring-tasks'
        const response = await this.doApiPost<string[]>(uri, JSON.stringify({ recurringTask, userId }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateRecurringTask(id: number, recurringTask: EditingTask, userId = -1): Promise<RecurringTask> {
        const uri = `/recurring-tasks/${id}`
        const response = await this.doApiPut<RecurringTask>(uri, JSON.stringify({ recurringTask, userId }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteRecurringTask(id: number): Promise<boolean> {
        const uri = `/recurring-tasks/${id}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new RecurringTaskService()
