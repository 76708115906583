import { BaseHttpService } from '@/services/base-http-service'
import { ServiceItemUser } from '@/types'

class ServiceItemUserService extends BaseHttpService {
    public async createServiceItemUser(serviceItemUser: Partial<ServiceItemUser>): Promise<ServiceItemUser> {
        const uri = '/service-item-users'
        const response = await this.doApiPost<ServiceItemUser>(uri, JSON.stringify({ data: serviceItemUser }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteServiceItemUser(id: number): Promise<boolean> {
        const uri = `/service-item-users/${id}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async loadServiceItemUsers(): Promise<boolean> {
        const uri = '/service-item-users/load'
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new ServiceItemUserService()
