import AgileStoryPointMeasureService from '@/services/agile-story-point-measure-service'
import AgileStoryPointLevelValueService from '@/services/agile-story-point-level-value-service'
import SprintService from '@/services/sprint-service'
import { AgileStoryPointMeasure, AgileStoryPointLevelValue, Sprint } from '@/types'
import { defineStore } from 'pinia'

interface AgileState {
    loading: boolean
    agileStoryPointMeasures: AgileStoryPointMeasure[]
    agileStoryPointLevelValues: AgileStoryPointLevelValue[]
    agileStoryPointMeasureRows: any[]
    agileStoryPointMeasureHeaderRow: string[]
    sprints: Sprint[]
}

export const useAgileStore = defineStore('agile', {
    state: (): AgileState => {
        return {
            loading: true,
            agileStoryPointMeasures: [],
            agileStoryPointLevelValues: [],
            agileStoryPointMeasureRows: [],
            agileStoryPointMeasureHeaderRow: [],
            sprints: [],
        }
    },

    actions: {
        async getSprints () {
            try {
                this.sprints = await SprintService.getSprints()
            } catch (err) {
                console.log(err)
            }
        },

        async getAgileStoryPointValues () {
            try {
                await this.getAgileStoryPointMeasures()
                await this.getAgileStoryPointLevelValues()
                await this.setAgileStoryPointMeasureGrid()
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async getAgileStoryPointMeasures () {
            try {
                this.agileStoryPointMeasures = await AgileStoryPointMeasureService.getAgileStoryPointMeasures()
            } catch (err) {
                console.log(err)
            }
        },

        async getAgileStoryPointLevelValues () {
            try {
                this.agileStoryPointLevelValues = await AgileStoryPointLevelValueService.getAgileStoryPointLevelValues()
            } catch (err) {
                console.log(err)
            }
        },

        async setAgileStoryPointMeasureGrid() {
            this.agileStoryPointMeasureHeaderRow = []
            this.agileStoryPointMeasureRows = []
            let gridY = -1
            const agileStoryPointLevels: any = {}

            this.agileStoryPointMeasureRows.push([])

            for (const agileStoryPointMeasure of this.agileStoryPointMeasures) {
                this.agileStoryPointMeasureHeaderRow.push(agileStoryPointMeasure.name)
                if (agileStoryPointMeasure.agileStoryPointLevels?.length) {
                    for (const agileStoryPointLevel of agileStoryPointMeasure.agileStoryPointLevels) {
                        agileStoryPointLevels[agileStoryPointLevel.id] = agileStoryPointLevel.name
                    }
                }
            }

            this.agileStoryPointMeasureHeaderRow.push('Point Value')
            this.agileStoryPointMeasureHeaderRow.push('Id')

            for (const agileStoryPointLevelValue of this.agileStoryPointLevelValues) {
                this.agileStoryPointMeasureRows.push([])
                gridY++

                for (const measureLevelGroup of agileStoryPointLevelValue.measureLevelGroup) {
                    this.agileStoryPointMeasureRows[gridY].push(agileStoryPointLevels[measureLevelGroup])
                }

                this.agileStoryPointMeasureRows[gridY].push(agileStoryPointLevelValue.storyPointValue)
                this.agileStoryPointMeasureRows[gridY].push(agileStoryPointLevelValue.id)
            }

            this.agileStoryPointMeasureRows.pop()
        },
    },
})
