import { defineStore } from 'pinia'
import UserService from '@/services/user-service'
import { RecordStatus, User } from '@/types'

interface UserState {
    users: User[]
}

export const useUserStore = defineStore('user', {
    state: (): UserState => {
        return {
            users: [],
        }
    },

    actions: {
        async getUsers (recordStatus: RecordStatus, zeytechEmployeesOnly = false) {
            try {
                this.users = await UserService.getUsers(recordStatus, zeytechEmployeesOnly)
            } catch (err) {
                console.log(err)
            }
        },
    },
})
