import { base64URLEncode, generateNonce, sha256 } from './utils'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CognitoUser } from './cognito-user'
import { jwtDecode } from 'jwt-decode'
import { Notify } from 'quasar'

class Auth {
  public async loginWithRedirect() {
    const state = await generateNonce()
    const codeVerifier = await generateNonce()
    sessionStorage.setItem('auth-state', state)
    sessionStorage.setItem(`codeVerifier-${state}`, codeVerifier)
    const codeChallenge = base64URLEncode(await sha256(codeVerifier))

    const redirectUrl = window.location.origin
    sessionStorage.setItem('auth-redirect-uri', redirectUrl)
    window.location.href = `${
        process.env.VUE_APP_AWS_COGNITO_DOMAIN
    }/login?response_type=code&client_id=${
        process.env.VUE_APP_AWS_COGNITO_CLIENT_ID
    }&state=${state}&code_challenge_method=S256&code_challenge=${codeChallenge}&redirect_uri=${
        redirectUrl
    }`
  }

  public async logout(showUnauthorizedMessage = false) {
    if (showUnauthorizedMessage) {
      Notify.create({ color: 'negative', message: 'Unauthorized. Please login, again.' })
      return new Promise(function (resolve) {
        setTimeout(resolve, 2000)
      })
    }

    const state = sessionStorage.getItem('auth-state')

    localStorage.removeItem('refresh-token')
    sessionStorage.removeItem('id-token')
    sessionStorage.removeItem('auth-access-token')

    const redirectUrl = sessionStorage.getItem('auth-redirect-uri')
    window.location.href = `${
        process.env.VUE_APP_AWS_COGNITO_DOMAIN
    }/logout?response_type=code&client_id=${
        process.env.VUE_APP_AWS_COGNITO_CLIENT_ID
    }&state=${state}&redirect_uri=${redirectUrl}`
  }

  public async getUser(): Promise<CognitoUser | null> {
    if (await this.isAuthenticated()) {
      const userRes = await fetch(`${process.env.VUE_APP_AWS_COGNITO_DOMAIN}/oauth2/userInfo`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${this.getAccessToken()}`
        })
      })

      if (userRes.status !== 200) {
        return null
      }

      return new CognitoUser(await userRes.json(), this.getUserGroups())
    }

    return null
  }

  public getUserGroups(): string[] {
    const accessToken = this.getAccessToken()

    if (accessToken) {
      const jwt: any = jwtDecode(accessToken)
      return jwt['cognito:groups']
        ? jwt['cognito:groups'].sort((a: string, b: string) => (a > b ? 1 : -1))
        : []
    }

    return []
  }

  public async isAuthenticated(): Promise<boolean> {
    const accessToken = this.getAccessToken()
    const refreshToken = this.getRefreshToken()

    if (!accessToken && !refreshToken) {
      return false
    } else if (accessToken && !this.tokenIsExpired(accessToken)) {
      return true
    } else if (refreshToken && (!accessToken || this.tokenIsExpired(accessToken))) {
      return await this.getTokensFromRefreshToken()
    }

    return true
  }

  public async getTokensFromRefreshToken(): Promise<boolean> {
    const redirectUri = sessionStorage.getItem('auth-redirect-uri')
    const tokenRes = await fetch(`${process.env.VUE_APP_AWS_COGNITO_DOMAIN}/oauth2/token`, {
      method: 'POST',
      headers: new Headers({ 'content-type': 'application/x-www-form-urlencoded' }),
      body: Object.entries({
        grant_type: 'refresh_token',
        client_id: process.env.VUE_APP_AWS_COGNITO_CLIENT_ID,
        redirect_uri: redirectUri || window.location.origin,
        refresh_token: this.getRefreshToken(),
      })
          .map(([k, v]) => `${k}=${v}`)
          .join('&')
    })

    const tokens = await tokenRes.json()

    if (tokens.error) {
      return false
    }

    if (tokens.id_token) {
      sessionStorage.setItem('id-token', tokens.id_token)
    } else {
      sessionStorage.removeItem('id-token')
    }
    if (tokens.access_token) {
      sessionStorage.setItem('auth-access-token', tokens.access_token)
    } else {
      sessionStorage.removeItem('auth-access-token')
    }

    return true
  }

  public async getIdTokenClaims(code: string, state: string) {
    window.history.replaceState({}, document.title, '/')

    const codeVerifier = sessionStorage.getItem(`codeVerifier-${state}`)
    if (codeVerifier === null) {
      throw new Error('Unexpected code')
    }
    const redirectUri = sessionStorage.getItem('auth-redirect-uri')
    const tokenRes = await fetch(`${process.env.VUE_APP_AWS_COGNITO_DOMAIN}/oauth2/token`, {
      method: 'POST',
      headers: new Headers({ 'content-type': 'application/x-www-form-urlencoded' }),
      body: Object.entries({
        grant_type: 'authorization_code',
        client_id: process.env.VUE_APP_AWS_COGNITO_CLIENT_ID,
        redirect_uri: redirectUri || window.location.origin,
        code: code,
        code_verifier: codeVerifier
      })
        .map(([k, v]) => `${k}=${v}`)
        .join('&')
    })

    const tokens = await tokenRes.json()

    if (tokens.refresh_token) {
      localStorage.setItem('refresh-token', tokens.refresh_token)
    } else {
      localStorage.removeItem('refresh_token')
    }
    if (tokens.id_token) {
      sessionStorage.setItem('id-token', tokens.id_token)
    } else {
      sessionStorage.removeItem('id-token')
    }
    if (tokens.access_token) {
      sessionStorage.setItem('auth-access-token', tokens.access_token)
    } else {
      sessionStorage.removeItem('auth-access-token')
    }

    return tokens
  }

  public getRefreshToken(): string | null {
    return localStorage.getItem('refresh-token')
  }

  public getIdToken(): string | null {
    return sessionStorage.getItem('id-token')
  }

  public getAccessToken(): string | null {
    return sessionStorage.getItem('auth-access-token')
  }

  public init(): { code: string | null; state: string | null } {
    const params = new URLSearchParams(window.location.search)
    const code = params.get('code')
    const state = params.get('state')

    return { code, state }
  }

  public tokenIsExpired(token: string | null): boolean {
    if (!token) {
      return true
    }

    const jwt: any = jwtDecode(token)

    if (!jwt?.exp) {
      return true
    }

    return Date.now() >= jwt.exp * 1000
  }
}

export default new Auth()
