import { defineStore } from 'pinia'
import ActivityTimelineService from '@/services/activity-timeline-service'
import Common from '@/util/common'
import { ActivityTimeline, ActivityTimelineType, BarStyle, Epic, PlanningActivityTimeline, Project, User,
    UserPlanningActivityTimeline, colors } from '@/types'

interface PlanningState {
    logs: any[]
}

export const usePlanningStore = defineStore('planning', {
    state: (): PlanningState => {
        return {
            logs: [],
        }
    },

    actions: {
        findRow(rows: any[], currentRowNumber: number, startDate: string, endDate: string): { totalRowNumber: number; groupRowNumber: number } {
            let groupRowNumber = 0
            for (const row of rows) {
                let availableRow = true

                for (const bar of row.bars) {
                    if (endDate >= bar.startDate && startDate <= bar.endDate) {
                        availableRow = false
                        break
                    }
                }
                if (availableRow) {
                    return { totalRowNumber: currentRowNumber, groupRowNumber: groupRowNumber }
                }
                currentRowNumber++
                groupRowNumber++
            }

            return { totalRowNumber: -1, groupRowNumber: -1 }
        },

        async getActivityTimelines (startDate: string, endDate: string, planningView: ActivityTimelineType, projectId: number|null,
                                           includeZeytechGeneralProject: boolean, userId: number|null, includeZeytechAutomationsUser: boolean,
                                    includeTeam: boolean) {
            try {
                const groupRows: any = {}
                const rows: any[] = []
                let results: Project[]|User[] = []
                if (planningView === ActivityTimelineType.Epic) {
                    results = await ActivityTimelineService.getEpicActivityTimelines(startDate, endDate, includeZeytechGeneralProject, projectId)
                } else if (planningView === ActivityTimelineType.Project) {
                    results = await ActivityTimelineService.getProjectActivityTimelines(startDate, endDate, includeZeytechGeneralProject, projectId)
                } else if (planningView === ActivityTimelineType.User) {
                    results = await ActivityTimelineService.getUserActivityTimelines(startDate, endDate, includeZeytechAutomationsUser, userId)
                } else {
                    results = await ActivityTimelineService.getPotentialAndNewProjectActivityTimelines(startDate, endDate, includeTeam)
                }

                const planningColors: any = {}
                let barCounter = 0
                let rowNumber = 0
                let startRowNumber
                let colorNumber = 0

                for (const result of results) {
                    startRowNumber = rowNumber
                    let counter = 1
                    let groupDatas: Epic[]|ActivityTimeline[]|PlanningActivityTimeline[]
                    if (planningView === ActivityTimelineType.Epic) {
                        groupDatas = (result as Project).epics?.length ? (result as Project).epics! : []
                    } else if (planningView === ActivityTimelineType.NewProject) {
                        groupDatas = (result as UserPlanningActivityTimeline).planningActivityTimelines?.length ? (result as UserPlanningActivityTimeline).planningActivityTimelines! : []
                    } else {
                        groupDatas = result.activityTimelines?.length ? result.activityTimelines : []
                    }
                    const resultGroupName = planningView === ActivityTimelineType.NewProject ? (result as UserPlanningActivityTimeline).fullName : planningView === ActivityTimelineType.User ? (result as User).fullName : (result as Project).name
                    if (planningView === ActivityTimelineType.NewProject || planningView === ActivityTimelineType.User) {
                        groupRows[resultGroupName] = {
                            rows: []
                        }
                    }
                    let barColor = colors[colorNumber++]

                    if (groupDatas.length) {
                        if (planningView !== ActivityTimelineType.NewProject && planningView !== ActivityTimelineType.User) {
                            groupRows[resultGroupName] = {
                                rows: []
                            }
                        }

                        for (const groupData of groupDatas) {
                            let groupStartDate
                            if (planningView === ActivityTimelineType.NewProject) {
                                groupStartDate = (Common.dateOnly(groupData.startDate)).concat(' 00:00')
                            } else {
                                groupStartDate = (Common.formatDate(groupData.startDate)).concat(' 00:00')
                            }
                            let groupEndDate
                            if (planningView === ActivityTimelineType.NewProject) {
                                groupEndDate = (Common.dateOnly(groupData.endDate)).concat(' 23:59')
                            } else {
                                groupEndDate = (Common.formatDate(groupData.endDate)).concat(' 23:59')
                            }

                            const originalEstimateSeconds = ActivityTimelineType.NewProject ? (groupData as ActivityTimeline).originalEstimateSeconds : 0
                            const plannedSeconds = ActivityTimelineType.NewProject ? (groupData as ActivityTimeline).timePlannedSeconds : 0

                            const timeRemaining: number|null = planningView === ActivityTimelineType.NewProject ?
                                Common.getHoursFromSeconds(originalEstimateSeconds - plannedSeconds) :
                                null

                            const style: BarStyle = {
                                color: barColor.textColor,
                                borderRadius: '20px',
                                backgroundColor: barColor.name,
                            }

                            if (planningView === ActivityTimelineType.NewProject && (result as UserPlanningActivityTimeline).fullName === 'Zeytech Automations') {
                                planningColors[groupData.id] = barColor
                                barColor = colors[colorNumber++]
                            }

                            if (planningView === ActivityTimelineType.NewProject) {
                                style.fontSize = '12px'
                                const attachedToId = (groupData as PlanningActivityTimeline).attachedToId
                                if (attachedToId) {
                                    style.backgroundColor = planningColors[attachedToId].name
                                }
                            }

                            const dateRangeText = `${Common.formatDate(groupStartDate, 0, 'M/dd')}-${Common.formatDate(groupEndDate, 0, 'M/dd')}`
                            const label = planningView === ActivityTimelineType.Epic ?
                                (groupData as Epic).name :
                                planningView === ActivityTimelineType.Project ?
                                    `${groupData.description}${(groupData as ActivityTimeline).user ? ` (${(groupData as ActivityTimeline).user!.fullName})` : ''} - ${dateRangeText}` :
                                    planningView === ActivityTimelineType.User ?
                                        `${groupData.description} (${Common.getHoursFromSeconds((groupData as ActivityTimeline).remainingEstimateSeconds)} remaining hrs) - ${dateRangeText}` :
                                        planningView === ActivityTimelineType.NewProject && (result as UserPlanningActivityTimeline).fullName !== 'Zeytech Automations' ?
                                            (groupData as PlanningActivityTimeline).totalHours.toFixed(2) :
                                            `${timeRemaining} planned hrs - ${groupData.description} 
                                                    (${Common.getHoursFromSeconds(originalEstimateSeconds)} original estimate hrs) - 
                                                    ${dateRangeText}`
                            const tooltip = planningView === ActivityTimelineType.NewProject && (result as UserPlanningActivityTimeline).fullName !== 'Zeytech Automations' ?
                                `${groupData.description} - ${dateRangeText}` :
                                label

                            const bar = {
                                startDate: groupStartDate,
                                endDate: groupEndDate,
                                ganttBarConfig: {
                                    id: barCounter++,
                                    label: label,
                                    tooltip: tooltip,
                                    style: style,
                                    immobile: true,
                                },
                            }

                            const { totalRowNumber, groupRowNumber } = this.findRow(groupRows[resultGroupName].rows, startRowNumber, groupStartDate, groupEndDate)

                            if (totalRowNumber === -1) {
                                let label = ''
                                if (counter++ === 1) {
                                    label = planningView === ActivityTimelineType.NewProject || planningView === ActivityTimelineType.User ? (result as User).fullName : (result as Project).name
                                }
                                const row = {
                                    id: groupData.id,
                                    label: label,
                                    bars: [bar],
                                }
                                rows.push(row)
                                groupRows[resultGroupName].rows.push(row)
                                rowNumber++
                            } else {
                                rows[totalRowNumber].bars.push(bar)
                                groupRows[resultGroupName].rows[groupRowNumber].bars.push(bar)
                            }
                        }
                    } else if (planningView === ActivityTimelineType.NewProject || planningView === ActivityTimelineType.User) {
                        const style: BarStyle = {
                            color: barColor.textColor,
                            borderRadius: '20px',
                            backgroundColor: barColor.name,
                        }

                        if (planningView === ActivityTimelineType.NewProject) {
                            style.fontSize = '12px'
                        }

                        const groupStartDate = (Common.formatDate(startDate)).concat(' 00:00')
                        const groupEndDate = (Common.formatDate(endDate)).concat(' 23:59')
                        const label = 'No activity timelines for selected date range'
                        const tooltip = `${label} - ${Common.formatDate(groupStartDate, 0, 'M/dd')}-${Common.formatDate(groupEndDate, 0, 'M/dd')}`

                        const row = {
                            id: result.id,
                            label: (result as User).fullName,
                            bars: [{
                                startDate: groupStartDate,
                                endDate: groupEndDate,
                                ganttBarConfig: {
                                    id: barCounter++,
                                    label: label,
                                    tooltip: tooltip,
                                    style: style,
                                    immobile: true,
                                },
                            }],
                        }
                        rows.push(row)
                        groupRows[resultGroupName].rows.push(row)
                        rowNumber++
                    }

                    if ((result as UserPlanningActivityTimeline).fullName === 'Zeytech Automations') {
                        colorNumber--
                    }
                }
                this.logs = rows
            } catch (err) {
                console.log(err)
            }
        },
    },
})
