import { BaseHttpService } from '@/services/base-http-service'
import { Ticket } from '@/types'

class TicketService extends BaseHttpService {
    public async getTicket(id: number): Promise<Ticket> {
        const uri = `/tickets/${id}`
        const response = await this.doApiGet<Ticket>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getByJiraKey(jiraKey: string): Promise<Ticket> {
        const uri = `/tickets?jiraKey=${jiraKey}`
        const response = await this.doApiGet<Ticket>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getTickets(startDate: string|null = null, endDate: string|null = null, userId: number|null = null): Promise<Ticket[]> {
        const uri = `/tickets${userId ? `?userId=${userId}` : ''}${startDate && endDate ? `${userId ? '&' : '?'}startDate=${startDate}&endDate=${endDate}` : ''}`
        const response = await this.doApiGet<Ticket[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateTicket(partialTicket: Partial<Ticket>): Promise<Ticket> {
        const uri = `/tickets/${partialTicket.id}`
        const response = await this.doApiPut<Ticket>(uri, JSON.stringify({ partialTicket }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getOverheadTickets(startDate: string, endDate: string, userId: number): Promise<Ticket[]> {
        const uri = `/tickets?label=overhead&startDate=${startDate}&endDate=${endDate}&userId=${userId}`
        const response = await this.doApiGet<Ticket[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getFavoritesTickets(userId: number): Promise<Ticket[]> {
        const uri = `/tickets?label=favorites&userId=${userId}`
        const response = await this.doApiGet<Ticket[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getRecentlyCreatedTickets(userId: number): Promise<Ticket[]> {
        const uri = `/tickets?label=recentlyCreated&userId=${userId}`
        const response = await this.doApiGet<Ticket[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateFavoritesTicket(ticketId: number, jiraKey: string, userId: number, type: 'add'|'remove'): Promise<boolean> {
        const uri = `/tickets/${ticketId}/update-favorite`
        const response = await this.doApiPut<boolean>(uri, JSON.stringify({ jiraKey, userId, type }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getTopMatchingTickets(searchText: string, limit = 10): Promise<Ticket[]> {
        const uri = `/tickets?searchText=${searchText}&limit=${limit}`
        const response = await this.doApiGet<Ticket[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async loadTickets(startDate: string, endDate: string): Promise<boolean> {
        const uri = '/tickets/load'
        const response = await this.doApiPost<boolean>(uri, JSON.stringify({ startDate: startDate, endDate: endDate }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async addCreatedByUserByJiraKey(jiraKey: string, userId: number): Promise<boolean> {
        const uri = `/tickets/${jiraKey}/add-created-by-user`
        const response = await this.doApiPut<boolean>(uri, JSON.stringify({ userId }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async addSprint(id: number, sprintId: number, currentSprintId: number|null = null): Promise<boolean> {
        const uri = `/tickets/${id}/add-sprint/${sprintId}`
        const response = await this.doApiPut<boolean>(uri, JSON.stringify({ currentSprintId }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async removeSprint(id: number, sprintId: number): Promise<boolean> {
        const uri = `/tickets/${id}/remove-sprint/${sprintId}`
        const response = await this.doApiPut<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getDistinctLabels(): Promise<string[]> {
        const uri = '/tickets?labelsOnly=true'
        const response = await this.doApiGet<string[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new TicketService()
