import { BaseHttpService } from '@/services/base-http-service'
import { AgileStoryPointLevelValue } from '@/types'

class AgileStoryPointLevelValueService extends BaseHttpService {
    public async getAgileStoryPointLevelValue(id: number): Promise<AgileStoryPointLevelValue> {
        const uri = `/agile-story-point-level-values/${id}`
        const response = await this.doApiGet<AgileStoryPointLevelValue>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getAgileStoryPointLevelValues(): Promise<AgileStoryPointLevelValue[]> {
        const uri = '/agile-story-point-level-values'
        const response = await this.doApiGet<AgileStoryPointLevelValue[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async savePointValue(id: number, pointValue: number): Promise<boolean> {
        const uri = `/agile-story-point-level-values/${id}`
        const response = await this.doApiPut<boolean>(uri, JSON.stringify({ pointValue }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new AgileStoryPointLevelValueService()
