import _ from 'lodash'

export class CognitoUser {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [key: string]: string | string[] | boolean | undefined | Function

  sub = ''
  username = ''
  email = ''
  emailVerified = false
  givenName = ''
  familyName = ''
  groups: string[] = []

  constructor(cognitoUser: { [key: string]: string | boolean | undefined }, groups: string[]) {
    if (!cognitoUser) return
    for (const key in cognitoUser) {
      this[_.camelCase(key)] = cognitoUser[key]
    }

    this.groups = groups
  }

  public toJSON() {
    return { ...this }
  }
}
