import { BaseHttpService } from '@/services/base-http-service'
import { FileOrientation, FileType, PdfAction } from '@/types'

class ExportService extends BaseHttpService {
    public async exportData(exportName: string, fileType: string, data: any[] = []) {
        const mimeType = fileType === FileType.CSV ? 'text/csv' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        const uri = `/exports/${exportName}`

        const response = await this.download(uri, mimeType, JSON.stringify({ fileType, data }))

        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async downloadOrEmailPdf(pdfAction: PdfAction, orientation: FileOrientation, includeZeytechFooter: boolean,
                                    reportName: string, reportValues: any) {
        const uri = `/pdf/download-or-email?pdfAction=${pdfAction}&orientation=${orientation}&includeZeytechFooter=${includeZeytechFooter}&reportName=${reportName}&reportValues=${JSON.stringify(reportValues)}`
        let response
        if (pdfAction === PdfAction.Download) {
            response = await this.downloadBinary(uri, 'application/pdf')
        } else {
            response = await this.doApiPost(uri)
        }
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new ExportService()
