<template>
  <div class="row left-align main-header">
    <div class="col-auto">
      Sticky Notes
    </div>
  </div>
  <div class="row left-align" style="margin-left: 10px; margin-right: 10px;">
    <table class="table table-sm align-middle">
      <tbody>
      <tr>
        <td style="width: 20%;">
          <router-link to="/sticky-notes" target="_blank">View Sticky Notes</router-link>
        </td>
        <td colspan="4" style="width: 80%;">

        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">

</script>

<style lang="scss" scoped>

</style>
