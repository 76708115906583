import { BaseHttpService } from '@/services/base-http-service'
import { UserStickyNoteSetting } from '@/types'

class UserStickyNoteSettingService extends BaseHttpService {
    public async getByUserId(userId: number): Promise<UserStickyNoteSetting> {
        const uri = `/user-sticky-note-settings/by-user-id?userId=${userId}`
        const response = await this.doApiGet<UserStickyNoteSetting>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateStickyNoteSettings(id: number, stickyNoteSettings: UserStickyNoteSetting): Promise<UserStickyNoteSetting> {
        const uri = `/user-sticky-note-settings/${id}`
        const response = await this.doApiPut<UserStickyNoteSetting>(uri, JSON.stringify({ data: stickyNoteSettings }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createStickyNoteSettings(userId: number): Promise<UserStickyNoteSetting> {
        const uri = `/user-sticky-note-settings/${userId}`
        const response = await this.doApiPost<UserStickyNoteSetting>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new UserStickyNoteSettingService()
