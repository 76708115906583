import { BaseHttpService } from '@/services/base-http-service'
import { SkillLevel } from '@/types'

class SkillLevelService extends BaseHttpService {
    public async getSkillLevel(id: number): Promise<SkillLevel> {
        const uri = `/skill-levels/${id}`
        const response = await this.doApiGet<SkillLevel>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getSkillLevels(): Promise<SkillLevel[]> {
        const uri = '/skill-levels'
        const response = await this.doApiGet<SkillLevel[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async loadSkillLevels(): Promise<boolean> {
        const uri = '/skill-levels/load'
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new SkillLevelService()
