import { BaseHttpService } from '@/services/base-http-service'
import { QuickBooksCategory } from '@/types'

class QuickBooksCategoryService extends BaseHttpService {
    public async getQuickBooksCategory(id: number): Promise<QuickBooksCategory> {
        const uri = `/quick-books-categories/${id}`
        const response = await this.doApiGet<QuickBooksCategory>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getQuickBooksCategories(): Promise<QuickBooksCategory[]> {
        const uri = '/quick-books-categories'
        const response = await this.doApiGet<QuickBooksCategory[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async loadQuickBooksCategories(): Promise<boolean> {
        const uri = '/quick-books-categories/load'
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new QuickBooksCategoryService()
