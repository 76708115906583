import { BaseHttpService } from '@/services/base-http-service'
import { Skill } from '@/types'

class SkillService extends BaseHttpService {
    public async getSkill(id: number): Promise<Skill> {
        const uri = `/skills/${id}`
        const response = await this.doApiGet<Skill>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getSkills(): Promise<Skill[]> {
        const uri = '/skills'
        const response = await this.doApiGet<Skill[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new SkillService()
