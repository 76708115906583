import { BaseHttpService } from '@/services/base-http-service'
import { Epic, EpicResourceSupportPlanStatusInformation, ResourcePlanDetail, SupportPlanDetail } from '@/types'

class EpicService extends BaseHttpService {
    public async getEpic(id: number, includeTickets = false): Promise<Epic> {
        const uri = `/epics/${id}?includeTickets=${includeTickets}`
        const response = await this.doApiGet<Epic>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getEpics(recordStatus: string, includeTickets = false, projectKey = '', userId: number|null = null): Promise<Epic[]> {
        const uri = `/epics?recordStatus=${recordStatus}&includeTickets=${includeTickets}&projectKey=${projectKey}${userId ? `&userId=${userId}` : ''}`
        const response = await this.doApiGet<Epic[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateEpic(epic: Partial<Epic>): Promise<Epic> {
        const uri = `/epics/${epic.id}`
        const response = await this.doApiPut<Epic>(uri, JSON.stringify({ data: epic }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async epicResourceSupportPlanStatus(epicId: number, epicStartDate: Date|null, epicEndDate: Date|null,
                                               resourcePlanDetail: ResourcePlanDetail|null, supportPlanDetail: SupportPlanDetail|null): Promise<EpicResourceSupportPlanStatusInformation[]> {
        const uri = `/epics/${epicId}/resource-support-plan-status`
        const response = await this.doApiPost<EpicResourceSupportPlanStatusInformation[]>(uri, JSON.stringify({
            epicStartDate: epicStartDate,
            epicEndDate: epicEndDate,
            resourcePlanDetail: resourcePlanDetail,
            supportPlanDetail: supportPlanDetail,
        }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async inactivateEpic(id: number): Promise<boolean> {
        const uri = `/epics/${id}/inactivate`
        const response = await this.doApiPut<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateEpicTSheetsId(id: number, epicName: string, projectTSheetsId: string): Promise<string> {
        const uri = `/epics/${id}/update-tsheets-id`
        const response = await this.doApiPut<string>(uri, JSON.stringify({ epicName, projectTSheetsId }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async loadEpics(startDate = '', endDate = ''): Promise<boolean> {
        const uri = '/epics/load'
        const response = await this.doApiPost<boolean>(uri, JSON.stringify({ startDate: startDate, endDate: endDate }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async removeUnusedEpicUsers(epicId: number): Promise<boolean> {
        const uri = `/epics/${epicId}/remove-unused-epic-users`
        const response = await this.doApiPut<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new EpicService()
