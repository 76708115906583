import { BaseHttpService } from '@/services/base-http-service'
import { Customer, NewOrganizationServiceDesk, Organization } from '@/types'

class OrganizationService extends BaseHttpService {
    public async createOrganizationServiceDesk(projectId: number, customers: Partial<Customer>[], keywords: string[]): Promise<NewOrganizationServiceDesk> {
        const uri = '/organizations/create-service-desk'
        const response = await this.doApiPost<NewOrganizationServiceDesk>(uri, JSON.stringify({ projectId, customers, keywords }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateOrganizationServiceDesk(organizationId: number, customers: Partial<Customer>[], keywords: string[]): Promise<Organization> {
        const uri = `/organizations/${organizationId}/update-service-desk`
        const response = await this.doApiPut<Organization>(uri, JSON.stringify({ customers, keywords }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async loadOrganizations(): Promise<boolean> {
        const uri = '/organizations/load'
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new OrganizationService()
