import { BaseHttpService } from '@/services/base-http-service'
import { SupportPlanDetail, SupportPlanDetailUser } from '@/types'

class SupportPlanDetailService extends BaseHttpService {
    public async addSupportPlanDetail(supportPlanDetail: Partial<SupportPlanDetail>): Promise<SupportPlanDetail> {
        const partialSupportPlanDetail = this.getPartialSupportPlanDetailObject(supportPlanDetail)

        const uri = '/support-plan-details'
        const response = await this.doApiPost<SupportPlanDetail>(uri, JSON.stringify({ partialSupportPlanDetail }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateSupportPlanDetail(supportPlanDetail: Partial<SupportPlanDetail>): Promise<SupportPlanDetail> {
        const partialSupportPlanDetail = this.getPartialSupportPlanDetailObject(supportPlanDetail)

        const uri = `/support-plan-details/${supportPlanDetail.id}`
        const response = await this.doApiPut<SupportPlanDetail>(uri, JSON.stringify({ partialSupportPlanDetail }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteSupportPlanDetail(id: number): Promise<boolean> {
        const uri = `/support-plan-details/${id}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createSupportPlanDetailUser(supportPlanDetailId: number, userId: number): Promise<SupportPlanDetailUser> {
        const uri = `/support-plan-details/${supportPlanDetailId}/create-user`
        const response = await this.doApiPost<SupportPlanDetailUser>(uri, JSON.stringify({ userId }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteSupportPlanDetailUser(supportPlanDetailId: number, userId: number): Promise<boolean> {
        const uri = `/support-plan-details/${supportPlanDetailId}/delete-user?userId=${userId}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public getPartialSupportPlanDetailObject (supportPlanDetail: Partial<SupportPlanDetail>) {
        return {
            epicId: supportPlanDetail.epicId,
            supportPlanType: supportPlanDetail.supportPlanType,
            baseServiceItemId: supportPlanDetail.baseServiceItem ? supportPlanDetail.baseServiceItem.id : null,
            budgetSeconds: supportPlanDetail.budgetSeconds,
            budgetDollars: supportPlanDetail.budgetDollars,
            basePreOverageSeconds: supportPlanDetail.basePreOverageSeconds,
            userId: supportPlanDetail.userId,
            name: supportPlanDetail.name,
            active: supportPlanDetail.active,
            supportPlanDetailEpicServiceItems: supportPlanDetail.supportPlanDetailEpicServiceItems,
            enabledUsersOnly: supportPlanDetail.enabledUsersOnly,
        }
    }
}

export default new SupportPlanDetailService()
