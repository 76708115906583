import { BaseHttpService } from '@/services/base-http-service'
import { StickyNote, StickyNoteStatus } from '@/types'

class StickyNoteService extends BaseHttpService {
    public async create(stickyNote: Partial<StickyNote>): Promise<StickyNote> {
        const uri = '/sticky-notes'
        const response = await this.doApiPost<StickyNote>(uri, JSON.stringify({ data: stickyNote }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async update(id: number, stickyNote: StickyNote): Promise<StickyNote> {
        const uri = `/sticky-notes/${id}`
        const response = await this.doApiPut<StickyNote>(uri, JSON.stringify({ data: stickyNote }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateStatus(id: number, status: StickyNoteStatus): Promise<boolean> {
        const uri = `/sticky-notes/${id}/update-status`
        const response = await this.doApiPut<boolean>(uri, JSON.stringify({ status: status }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async delete(id: number): Promise<boolean> {
        const uri = `/sticky-notes/${id}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getStickyNotes(userId: number): Promise<StickyNote[]> {
        const uri = `/sticky-notes?userId=${userId}`
        const response = await this.doApiGet<StickyNote[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new StickyNoteService()
